import axios from "axios";
import * as types from "./types";

export default {
  [types.SEARCH_CURRENT_COMMISSIONS]: ({ commit }, payload) => {
    var resource = `/api/commissions/${payload.year}/${payload.month}?page=${payload.currentPage}`;
    delete payload.year;
    delete payload.month;
    delete payload.currentPage;
    delete payload.periodType;
    return axios
      .post(resource, payload)
      .then((resp) => {
        commit(types.MUTATE_CURRENT_COMMISSIONS_SEARCH_RESULT, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.SEARCH_HISTORICAL_COMMISSIONS]: ({ commit }, payload) => {
    var resource = `/api/commissions/history`;
    delete payload.currentPage;
    return axios
      .post(resource, payload)
      .then((resp) => {
        commit(types.MUTATE_HISTORICAL_COMMISSIONS_SEARCH_RESULT, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.SEARCH_PAST_FUTURE_COMMISSIONS]: ({ commit }, payload) => {
    var resource = `/api/commissions/past_future`;
    delete payload.currentPage;
    return axios
      .post(resource, payload)
      .then((resp) => {
        commit(types.MUTATE_PAST_FUTURE_COMMISSIONS_SEARCH_RESULT, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_COMMISSION_PRODUCTS]: ({ commit }, payload) => {
    return axios
      .get("/api/common/commission_product_types")
      .then((resp) => {
        commit(types.MUTATE_COMMISSION_PRODUCTS, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_HAS_COMISSIONS_REPORT]: ({}, payload) => {
    return axios
      .post("/api/reports/have_contracts_payments_and_comissions", payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.EXPORT_CONTRACTS_COMISSIONS]: ({ commit }, payload) => {
    return axios.post(
      '/api/reports/export_contracts_comissions',
      payload,
      { responseType: 'blob' },
    )
      .then(resp => {
        let fileURL = window.URL.createObjectURL(new Blob([resp.data]));
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'comissoes.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.SEARCH_COMMISSION_MANAGEMENT]: ({ commit }, payload) => {
    const resource = `/api/commission_management/search?page=${payload.currentPage}`;
    delete payload.currentPage;
    return axios
      .post(resource, payload)
      .then((resp) => {
        commit(types.MUTATE_COMMISSION_MANAGEMENT_SEARCH_RESULT, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.COMMISSION_MANAGEMENT_TOTALIZER]: ({ commit }, payload) => {
    const resource = `/api/commission_management/totalizer`;
    delete payload.currentPage;
    return axios
      .post(resource, payload)
      .then((resp) => {
        commit(types.MUTATE_COMMISSION_MANAGEMENT_TOTALIZER, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_PAYMENT_CONCILIATORS]: ({ commit }, payload) => {
    return axios
      .get(`/api/common/payment_conciliation_types`)
      .then((resp) => {
        commit(types.MUTATE_PAYMENT_CONCILIATORS, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_FINANCIAL_SOLUTIONS]: ({ commit }, payload) => {
    return axios
      .get("/api/financial_solutions/summary", { params: payload })
      .then((resp) => {
        commit(types.MUTATE_FINANCIAL_SOLUTIONS, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_PAYMENT_PRODUCTS]: ({ commit }, payload) => {
    return axios
      .get(`/api/common/payment_product_types`)
      .then((resp) => {
        commit(types.MUTATE_PAYMENT_PRODUCTS, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.SEARCH_CHART_COMMISSION_MANAGEMENT]: ({ commit }, payload) => {
    const resource = `/api/commission_management/summary`;
    return axios
      .post(resource, payload)
      .then((resp) => {
        commit(types.MUTATE_CHART_COMMISSION_MANAGEMENT, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.EXPORT_COMMISSIONS_FOCUSNFE]: ({ commit }, payload) => {
    return axios.post(
      '/api/reports/export_comissions_focusnfe',
      payload,
      { responseType: 'blob' },
    )
      .then(resp => {
        let fileURL = window.URL.createObjectURL(new Blob([resp.data]));
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'relatorio-comissoes-focusnfe.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
};
