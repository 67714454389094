<template>
  <div>
    <!-- Filters -->
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5 class="mb-1">Filtros</h5>
      </b-card-header>
      <b-card-body>
        <b-row class="mt-1">
          <b-col cols="12" md="3">
            <b-form-group label="Adicionado por" label-for="added_by">
              <b-form-input
                id="added_by"
                v-model="added_by"
                @keyup.enter="search"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Mês competência" label-for="month">
              <v-select
                id="month"
                v-model="month"
                :options="months"
                label="label"
              >
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Ano competência" label-for="year">
              <input
                id="year"
                v-model.number="year"
                type="number"
                class="form-control"
                placeholder="Digite o ano"
                :min="1900"
                :max="actualYear"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mt-1 mb-md-0 mb-2 text-right">
            <b-button
              variant="outline-secondary"
              @click.prevent="clearSearch"
              :disabled="loading"
              v-text="'Limpar'"
            />
            &nbsp;
            <b-button
              variant="outline-primary"
              @click.prevent="search"
              :disabled="loading"
            >
              <b-spinner v-if="loading" small />
              <feather-icon v-else icon="SearchIcon" class="mr-50" />
              <span>
                {{ loading ? "Aguarde..." : "Pesquisar" }}
              </span>
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibir</label>
            <v-select
              v-model="itemsPerPage"
              dir="ltr"
              :options="itemsPerPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="search(currentPage)"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <label>itens</label>
          </b-col>
        </b-row>
      </div>
      <b-table
        hover
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="user_id"
        show-empty
        empty-text="Não foram encontradas importações para esta busca"
        :busy="loading"
        :no-local-sorting="true"
        :sort-by="sortField"
        @sort-changed="sort"
      >
        <!-- Column: month -->
        <template #cell(month)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.month }}</span>
          </div>
        </template>
        <!-- Column: year -->
        <template #cell(year)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.year }}</span>
          </div>
        </template>
        <!-- Column: added_by -->
        <template #cell(added_by)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.added_by }}</span>
          </div>
        </template>
        <!-- Column: created_at -->
        <template #cell(created_at)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.created_at | dateTime
            }}</span>
          </div>
        </template>
        <!-- Column: updated_at -->
        <template #cell(updated_at)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.updated_at | dateTime
            }}</span>
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret right boundary="window">
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="deleteImportEnabled"
              @click="deleteItem(data.item)"
            >   
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Excluir</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="modalInfo(data.item)"
            >   
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Histórico de Importação</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Exibindo {{ pageItemsFrom }} à {{ pageItemsTo }} de
              {{ totalItems }} itens</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="itemsPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="search"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
  BFormGroup,
  BPopover,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {
  CREATE_ACTION,
  DELETE_ACTION,
  CONSULTANT_RESOURCE,
  READ_ACTION,
} from "@/constants/resources";
import UserDetailPopover from "@/modules/shared/components/UserDetailPopover";
import ShortcutButtonsPlugin from "shortcut-buttons-flatpickr";
import todayButton from "@/helpers/todayButton";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import * as accountTypes from "@/modules/account/store/types";

import flatPickr from "vue-flatpickr-component";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import { assertOptions } from "@/constants/assertions";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BFormGroup,
    BPopover,
    vSelect,
    flatPickr,
    UserDetailPopover,
  },
  setup() {
    const { skin } = useAppConfig();
    const actualYear = new Date().getFullYear();

    return { toast: useToast(), skin, actualYear };
  },
  data() {
    return {
      loading: false,
      tableColumns: [
        { key: "month", label: "Mês", sortable: true },
        { key: "year", label: "Ano", sortable: true },
        { key: "added_by", label: "Adicionado por", sortable: true },
        { key: "created_at", label: "Criado em", sortable: true },
        { key: "updated_at", label: "Atualizado em", sortable: true },
        { key: "actions", label: "Ações" },
      ],
      flatPickrConfig: {
        altFormat: "j M Y",
        altInput: true,
        mode: "range",
        dateFormat: "Y-m-d",
        locale: Portuguese,
        plugins: [ShortcutButtonsPlugin(todayButton)],
      },
      assertOptions,
      currentPage: 1,
      itemsPerPage: 1,
      order: undefined,
      sortField: null,
      month: undefined,
      year: undefined,
      added_by: "",
      months: [
        "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
        "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
      ],
      actualYear: new Date().getFullYear(),
    };
  },
  computed: {
    ...mapGetters({
      importsSearchResult: types.IMPORT_SEARCH_RESULT,
      importsHistorySearchResult: types.IMPORT_HISTORY_SEARCH_RESULT,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
      user: accountTypes.USER,
    }),
    readConsultantEnabled: function () {
      return this.$can(READ_ACTION, CONSULTANT_RESOURCE);
    },
    createConsultantEnabled: function () {
      return this.$can(CREATE_ACTION, CONSULTANT_RESOURCE);
    },
    deleteImportEnabled: function () {
      return this.$can(DELETE_ACTION, CONSULTANT_RESOURCE);
    },
    items: function () {
      return this.importsSearchResult?.data || [];
    },
    totalItems: function () {
      return this.importsSearchResult?.total || 0;
    },
    pageItemsFrom: function () {
      return this.importsSearchResult?.from || 0;
    },
    pageItemsTo: function () {
      return this.importsSearchResult?.to || 0;
    }
  },
  mounted() {
    this.itemsPerPage = this.initialItemsPerPage;
    this.search();
  },
  methods: {
    ...mapActions({
      searchImports: types.SEARCH_IMPORTS,
      searchImportHistory: types.SEARCH_IMPORT_HISTORY,
      deleteImport: types.DELETE_IMPORT,
      getAffiliationTypes: types.GET_AFFILIATION_TYPES,
    }),
    search(currentPage = undefined) {
      this.loading = true;
      const {
        order,
        active,
        itemsPerPage,
        month,
        year,
        added_by
      } = this;
      this.searchImports({
        order,
        active,
        limit: itemsPerPage,
        month,
        year,
        added_by,
        currentPage: Number.isInteger(currentPage)
          ? currentPage
          : this.currentPage,
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar as importações. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteItem(item) {
      if(!item.deleted_at){
        this.$swal({
          title: "Tem certeza?",
          text: ``,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Excluir",
          cancelButtonText: "Cancelar",
          background: `${this.skin === "dark" ? "#283046" : ""}`,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-secondary ml-1",
            htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
          },
        }).then((result) => {
          if (result.value) {
            this.deleteImport(item.id)
              .then((response) => {
                this.search();
                this.toast({
                  component: ToastificationContent,
                  props: {
                    title: "Sucesso",
                    text: `O registro foi excluído.`,
                    icon: "CoffeeIcon",
                    variant: "success",
                  },
                });
              })
              .catch((error) => {
                this.toast({
                  component: ToastificationContent,
                  props: {
                    title: "Oops!",
                    text: `Ocorreu um erro ao excluir o registro. Entre em contato com o setor de TI.`,
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                });
              })
              .finally(() => {
                this.loading = false;
              });
          }
        });
      }
    },
    modalInfo(item) {
      this.$swal({
        backdrop: true,
        title: `Histórico de Importação`,
        html: `
          <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; height: 100%; width: 100%;">
            <div id="tableContainer" style="margin-top: 20px; max-height: 50vh; overflow-y: auto; width: 100%; text-align: center;">
            </div>
          </div>
        `,
        icon: "info",
        confirmButtonText: "Fechar",
        allowOutsideClick: false,
        allowEscapeKey: false,
        width: "60vw",
        heightAuto: false,
        background: `${this.skin === "dark" ? "#283046" : ""}`,
        customClass: {
          popup: "modal-height",
          confirmButton: "btn btn-primary",
          title: "text-primary",
          htmlContainer: `announcement-body info-responsive ${
            this.skin === "dark" ? "text-white" : ""
          }`,
        },
        didOpen: () => {
          const tableContainer = document.getElementById("tableContainer");

          const payload = {
            idImport: item.id,
            currentPage: 1,
          };

          this.searchImportHistory(payload)
            .then((resp) => {
              const rows = resp.data.data;
              const headersMap = {
                progress: "Progresso",
                consultant_name: "Nome do Consultor",
                consultant_email: "Email do Consultor",
                consultant_department: "Departamento",
                month: "Mês",
                year: "Ano",
              };

              if (rows.length > 0) {
                let tableHtml = `<table class="table table-striped">
                  <thead>
                    <tr>${Object.keys(headersMap)
                      .map((key) => `<th>${headersMap[key]}</th>`)
                      .join("")}</tr>
                  </thead>
                  <tbody>
                    ${rows
                      .map(
                        (row) =>
                          `<tr>${Object.keys(headersMap)
                            .map(
                              (key) =>
                                `<td>${row[key] !== undefined ? row[key] : ""}</td>`
                            )
                            .join("")}</tr>`
                      )
                      .join("")}
                  </tbody>
                </table>`;

                tableContainer.innerHTML = tableHtml;
              } else {
                tableContainer.innerHTML =
                  "<p>Nenhum dado encontrado para exibição.</p>";
              }
            })
            .catch((err) => {
              tableContainer.innerHTML = `<p style="color: red;">Erro ao carregar os dados: ${err.message}</p>`;
            });
        },
      });
    },
    clearSearch() {
      this.month = "";
      this.year = "";
      this.added_by = "";
      this.search();
    },
    sort(event) {
      if (!event.sortDesc && this.sortField == event.sortBy) {
        this.order = undefined;
        this.sortField = null;
      } else {
        var direction = event.sortDesc ? " DESC" : " ASC";
        this.order = event.sortBy + direction;
        this.sortField = event.sortBy;
      }
      this.search();
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
