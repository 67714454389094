import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  overdueSignatures: undefined,
  inactivePnContracts: undefined,
  inactivePnCustomers: undefined,
  contractAndCustomers: undefined,
  epsSubscriptionsAndSolutions: undefined,
}

export default {
  state,
  actions,
  getters,
  mutations
}
