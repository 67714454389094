var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card-header',{staticClass:"pb-50"},[_c('h5',[_vm._v(" Filtros ")])]),_c('b-card-body',[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"PN","label-for":"searchConsultant"}},[_c('v-select',{attrs:{"id":"searchConsultant","multiple":"","reduce":function (consultant_list) { return consultant_list.id; },"options":_vm.consultantList,"loading":_vm.loading.consultants,"label":"name"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search.apply(null, arguments)}},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Sem resultados ")]}}]),model:{value:(_vm.consultant),callback:function ($$v) {_vm.consultant=$$v},expression:"consultant"}})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Colaborador","label-for":"searchPartner"}},[_c('v-select',{attrs:{"id":"searchPartner","multiple":"","reduce":function (employee_list) { return employee_list.id; },"options":_vm.employees,"loading":_vm.loading.employees,"label":"name"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search.apply(null, arguments)}},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Sem resultados ")]}}]),model:{value:(_vm.employee),callback:function ($$v) {_vm.employee=$$v},expression:"employee"}})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Franqueado","label-for":"searchFranchise"}},[_c('v-select',{attrs:{"id":"searchFranchise","multiple":"","reduce":function (franchise_list) { return franchise_list.id; },"options":_vm.franchises,"loading":_vm.loading.franchises,"close-on-select":false,"label":"name"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search.apply(null, arguments)}},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Sem resultados ")]}}]),model:{value:(_vm.franchise),callback:function ($$v) {_vm.franchise=$$v},expression:"franchise"}})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Período","label-for":"searchRangeDate"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"searchRangeDate","config":_vm.flatPickrConfig,"placeholder":("01 Jan 2020 até 31 Dez " + _vm.actualYear)},model:{value:(_vm.rangeDate),callback:function ($$v) {_vm.rangeDate=$$v},expression:"rangeDate"}})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Departamento","label-for":"searchDepartment"}},[_c('v-select',{attrs:{"id":"searchDepartment","multiple":"","reduce":function (department_list) { return department_list.id; },"options":_vm.departments,"loading":_vm.loading.departments,"label":"name"},on:{"change":_vm.updateCategories},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v("Sem resultados")]}}]),model:{value:(_vm.department),callback:function ($$v) {_vm.department=$$v},expression:"department"}})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Categoria","label-for":"searchCategory"}},[_c('v-select',{attrs:{"id":"searchCategory","multiple":"","reduce":function (category) { return category.id; },"options":_vm.filteredCategories,"loading":_vm.loading.categories,"label":"name"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search.apply(null, arguments)}},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v("Selecione um departamento")]}}]),model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Tipo","label-for":"searchCategory"}},[_c('v-select',{attrs:{"id":"searchType","reduce":function (type) { return type.key; },"options":_vm.withdrawalTypes,"loading":_vm.loading.withdrawalTypes,"label":"name"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search.apply(null, arguments)}},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Sem resultados ")]}}]),model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Status","label-for":"searchStatuses"}},[_c('v-select',{attrs:{"id":"searchStatuses","reduce":function (statuses) { return statuses.key; },"options":_vm.withdrawalStatuses,"loading":_vm.loading.withdrawalStatuses,"label":"name"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search.apply(null, arguments)}},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Sem resultados ")]}}]),model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Nível","label-for":"searchTeam"}},[_c('v-select',{attrs:{"id":"searchRole","multiple":"","reduce":function (role_list) { return role_list.id_consultant_role; },"options":_vm.roles,"loading":_vm.loading.roles,"close-on-select":false,"label":"name"},on:{"input":function($event){_vm.consultant = []},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search.apply(null, arguments)}},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Sem resultados ")]}}]),model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Franquias física","label-for":"searchPhysicalFranchise"}},[_c('v-select',{attrs:{"id":"searchPhysicalFranchise","multiple":"","reduce":function (physical_franchise_list) { return physical_franchise_list.id; },"options":_vm.physicalFranchises,"loading":_vm.loading.physicalFranchises,"label":"name"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search.apply(null, arguments)}},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Sem resultados ")]}}]),model:{value:(_vm.physicalFranchise),callback:function ($$v) {_vm.physicalFranchise=$$v},expression:"physicalFranchise"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }