<template>
  <div>
    <save-expense-sidebar />
    <div style="position: relative" v-if="canCreate">
      <app-breadcrumb :breadcrumbTitle="false">
        <b-button
          @click="editExpense(undefined)"
          variant="primary"
          style="position: absolute; right: 13"
        >
          <feather-icon icon="PlusCircleIcon" class="mr-50" />
          <span class="text-nowrap">Nova despesa</span>
        </b-button>
      </app-breadcrumb>
    </div>
    <yearly-incomes :year="year" ref="yearlyIncomes"/>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <expenses-filters
        :spinnerActive="loading"
        :search="search"
        ref="filters"
      />
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibir</label>
            <v-select
              v-model="itemsPerPage"
              dir="ltr"
              :options="itemsPerPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="search(currentPage)"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <label>itens</label>
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="outline-secondary"
                @click.prevent="clearSearch"
                class="mr-1"
                :disabled="loading"
              >
                Limpar filtros
              </b-button>
              <b-button
                variant="primary"
                @click.prevent="search(1)"
                :disabled="loading"
              >
                <b-spinner v-if="loading" small />
                <feather-icon v-else icon="SearchIcon" class="mr-50" />
                <span>
                  {{ loading ? "Aguarde..." : "Pesquisar" }}
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        hover
        class="position-relative"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Não foram encontrados produtos para esta busca"
        :busy="loading"
        :no-local-sorting="true"
        :sort-by="sortField"
        @sort-changed="sort"
      >
        <!-- Column: User -->
        <template #cell(name)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.name
            }}</span>
          </div>
        </template>
        <!-- Column: Role -->
        <template #cell(cost_center)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.cost_center
            }}</span>
          </div>
        </template>
        <!-- Column: Status -->
        <template #cell(cost_center_category)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.cost_center_category
            }}</span>
          </div>
        </template>
        <!-- Column: Role -->
        <template #cell(amount)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.amount | toCurrency
            }}</span>
          </div>
        </template>
        <!-- Column: Status -->
        <template #cell(launch_date)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.launch_date | onlyDate
            }}</span>
          </div>
        </template>
        <!-- Column: Status -->
        <template #cell(description)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.description
            }}</span>
          </div>
        </template>
        <!-- Column: Status -->
        <template #cell(payment_type)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.payment_type
            }}</span>
          </div>
        </template>
        <!-- Column: Status -->
        <template #cell(payment_key)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.payment_key
            }}</span>
          </div>
        </template>
        <template #cell(group_company)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.group_company
            }}</span>
          </div>
        </template>
        <template #cell(added_by_name)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.added_by_name
            }}</span>
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            right
            v-if="!data.item.withdrawal_id"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item v-if="canUpdate" @click="editExpense(data.item.id)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="canDelete" @click="deleteItem(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Remover</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Exibindo {{ pageItemsFrom }} à {{ pageItemsTo }} de
              {{ totalItems }} itens</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="itemsPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="search"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    
    <!-- Table Container Card -->
    <div style="margin-top:20">
      <total-expenses-by-cost-center-chart :year="year" ref="centerChart"/>
    </div>
    <template>
      <competencies-list @cost-center-clicked="scrollToCostCenter" ref="competencieTotalizer"/>
    </template>

    <template v-if="!loadingIndividual" v-for="(costCenterData, costCenter) in costCenters">
      <competencies-list-individual
        :ref="`cost-center-${costCenter}`"
        :costCenter="costCenter"
        :data="costCenterData"
        :loadingIndividual="loadingIndividual"
      />
    </template>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import ExpensesFilters from "../components/ExpensesFilters.vue";
import { getRangeDates } from "@/helpers/date_picker";
import AppBreadcrumb from "@core/layouts/components/AppBreadcrumb.vue";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import useAppConfig from "@core/app-config/useAppConfig";
import SaveExpenseSidebar from "../components/SaveExpenseSidebar.vue";
import YearlyIncomes from "../components/YearlyIncomes.vue";
import TotalExpensesByCostCenterChart from "../components/TotalExpensesByCostCenterChart.vue";
import CompetenciesList from "../components/CompetenciesList.vue";
import CompetenciesListIndividual from "../components/CompetenciesListIndividual.vue";
import {
  CREATE_ACTION,
  UPDATE_ACTION,
  DELETE_ACTION,
  PAYMENT_IMPORT
} from "@/constants/resources";


export default {
  components: {
    vSelect,
    ExpensesFilters,
    AppBreadcrumb,
    SaveExpenseSidebar,
    YearlyIncomes,
    CompetenciesList,
    CompetenciesListIndividual,
    TotalExpensesByCostCenterChart
  },
  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), skin };
  },
  data() {
    return {
      loading: false,
      tableColumns: [
        { key: "name", label: "Nome", sortable: true },
        { key: "cost_center", label: "Centro de custo", sortable: true },
        {
          key: "cost_center_category",
          label: "Categoria do centro",
          sortable: true,
        },
        {
          key: "consultant_name",
          label: "Colaborador",
          sortable: true,
        },
        { key: "amount", label: "Valor", sortable: true },
        { key: "launch_date", label: "Data da despesa", sortable: true },
        { key: "description", label: "Descrição", sortable: true },
        { key: "payment_type", label: "Tipo de pagamento", sortable: true },
        { key: "payment_key", label: "Chave de pagamento", sortable: true },
        { key: "group_company", label: "Empresa", sortable: true },
        { key: "added_by_name", label: "Criado por", sortable: true },
        { key: "actions", label: "Ações" },
      ],
      currentPage: 1,
      itemsPerPage: 1,
      order: undefined,
      sortField: null,
      loadingIndividual: false,
      year: new Date().getFullYear()
    };
  },
  computed: {
    ...mapGetters({
      searchResult: types.EXPENSES_SEARCH_RESULT,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
      competenciesExpenses: types.COMPETENCIES_EXPENSES,
      expenseSearchYear: types.EXPENSES_SEARCH_YEAR
    }),
    canCreate() {
      return this.$can(CREATE_ACTION, PAYMENT_IMPORT);
    },
    canUpdate() {
      return this.$can(UPDATE_ACTION, PAYMENT_IMPORT);
    },
    canDelete() {
      return this.$can(DELETE_ACTION, PAYMENT_IMPORT);
    },
    items: function () {
      return this.searchResult?.data || [];
    },
    totalItems: function () {
      return this.searchResult?.total || 0;
    },
    pageItemsFrom: function () {
      return this.searchResult?.from || 0;
    },
    pageItemsTo: function () {
      return this.searchResult?.to || 0;
    },
    costCenters() {
      const costCenters = {};
      const categories = this.competenciesExpenses?.categories || {};

      Object.keys(categories).forEach((month) => {
        categories[month].forEach((category) => {
          if (!costCenters[category.cost_center]) {
            costCenters[category.cost_center] = {};
          }
          costCenters[category.cost_center][month] = category.categories;
        });
      });

      return costCenters;
    }
  },
  mounted() {
    this.itemsPerPage = this.initialItemsPerPage;
    this.search();
  },
  methods: {
    ...mapActions({
      searchExpenses: types.SEARCH_EXPENSES,
      deleteExpense: types.DELETE_EXPENSE,
      openExpenseSidebar: types.OPEN_EXPENSE_SIDEBAR,
      getCompetenciesExpenses: types.GET_COMPETENCIES_EXPENSES
    }),
    scrollToCostCenter(costCenter) {
      const ref = this.$refs[`cost-center-${costCenter}`][0];
      if (ref) {
        ref.visible = true;
        ref.$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    },
    search(currentPage) {
      this.loading = true;
      const {
        name,
        period,
        costCenter,
        groupCompany,
        paymentType,
        paymentKey,
        costCenterCategory,
      } = this.$refs.filters;
      const date = getRangeDates(period)
      this.searchExpenses({
        name,
        cost_center: costCenter,
        cost_center_category: costCenterCategory,
        group_company: groupCompany,
        payment_type: paymentType,
        payment_key: paymentKey,
        launch_date_start: date.start,
        launch_date_end: date.end,
        year: this.expenseSearchYear,
        limit: this.itemsPerPage,
        currentPage: Number.isInteger(currentPage)
          ? currentPage
          : this.currentPage,
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar as despesas. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
      this.loadAdditionals()
    },
    loadAdditionals(){
      this.loadCompetencies() 
      if(this.$refs.yearlyIncomes){
        this.$refs.yearlyIncomes.getData()
      }
      if(this.$refs.centerChart){
        this.$refs.centerChart.getData()
      }
      if(this.$refs.competencieTotalizer){
        this.$refs.competencieTotalizer.getData()
      }
    },
    loadCompetencies(){
      this.loadingIndividual = true;
      this.getCompetenciesExpenses(this.expenseSearchYear)
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar a lista de competências. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loadingIndividual = false;
        });
    },
    sort(event) {
      //clear in third click
      if (!event.sortDesc && this.sortField == event.sortBy) {
        this.order = undefined;
        this.sortField = null;
      } else {
        var direction = event.sortDesc ? " DESC" : " ASC";
        this.order = event.sortBy + direction;
        this.sortField = event.sortBy;
      }
      this.search();
    },
    deleteItem(item) {
      this.$swal({
        title: "Tem certeza?",
        text: `Você removerá a despesa ${item.name}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar",
        background: `${this.skin === "dark" ? "#283046" : ""}`,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-secondary ml-1",
          htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
        },
      }).then((result) => {
        if (result.value) {
          this.deleteExpense(item.id)
            .then((response) => {
              this.search();
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Sucesso",
                  text: `A despesa ${item.name} foi removida`,
                  icon: "CoffeeIcon",
                  variant: "success",
                },
              });
            })
            .catch((error) => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Oops!",
                  text: `Existe um pedido de saque vinculado à despesa ${item.name} e não pode ser removido. Entre em contato com o setor de TI.`,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    editExpense(id) {
      this.openExpenseSidebar({ id, saveAction: this.search });
    },
    clearSearch() {
      this.$refs.filters.name = undefined;
      this.$refs.filters.period = undefined;
      this.$refs.filters.costCenter = undefined;
      this.$refs.filters.groupCompany = undefined;
      this.$refs.filters.paymentType = undefined;
      this.$refs.filters.callReminder = undefined;
      this.$refs.filters.paymentKey = undefined;
      this.$refs.filters.costCenterCategory = undefined;
      this.$refs.filters.year = new Date().getFullYear();
      this.search(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
