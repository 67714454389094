<template>
  <div>
    <!-- Filters -->
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5 class="mb-1">Filtros</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="6" class="mb-md-0 mb-2">
            <b-form-group label="PN" label-for="searchConsultant">
              <all-select
                id="searchConsultant"
                v-model="consultant"
                :options="consultants"
                label="name"
                take-this="id"
                track-by="id"
                multiple
                :close-on-select="false"
                :loading="loading.consultants"
              ></all-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" class="mb-md-0 mb-2">
            <b-form-group label="Franqueado" label-for="searchFranchise">
              <all-select
                id="searchFranchise"
                v-model="franchise"
                :options="franchises"
                label="name"
                take-this="id"
                track-by="id"
                multiple
                :close-on-select="false"
                :loading="loading.franchises"
              ></all-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mb-md-0 mb-2 text-right">
            <b-button
              variant="outline-secondary"
              @click.prevent="clearSearch"
              class="mr-1"
              :disabled="loading.search"
            >
              Limpar
            </b-button>
            <b-button
              variant="outline-primary"
              @click.prevent="search()"
              :disabled="loading.search"
            >
              <b-spinner v-if="loading.search" small />
              <feather-icon v-else icon="SearchIcon" class="mr-50" />
              <span>
                {{ loading.search ? "Aguarde..." : "Pesquisar" }}
              </span>
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibir</label>
            <v-select
              v-model="itemsPerPage"
              dir="ltr"
              :options="itemsPerPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="search(currentPage)"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <label>itens</label>
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="6">
            <b-row class="d-flex align-items-center justify-content-end">
              <div class="mx-1" cols="12" md="6">
                <b-button
                  variant="outline-primary"
                  :disabled="loading.export"
                  @click.prevent="exportData()"
                >
                  <b-spinner v-if="loading.export" small />
                  <feather-icon
                    v-else
                    icon="ArrowDownCircleIcon"
                    class="mr-50"
                  />
                  <span>
                    {{ loading.export ? "Aguarde..." : "Exportar planilha" }}
                  </span>
                </b-button>
              </div>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <b-table
        hover
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Não foram encontrados contratos para esta busca"
        :busy="loading.search"
        :no-local-sorting="true"
        :sort-by="sortField"
        @sort-changed="sort"
        @row-clicked="goToContractDetails"
      >
        <!-- Column: Nome -->
        <template #cell(name)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.name }}</span>
          </div>
        </template>
        <!-- Column: CPF -->
        <template #cell(cpf)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top">{{ data.item.cpf }}</span>
          </div>
        </template>
        <!-- Column: Email -->
        <template #cell(email)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.email }}</span>
          </div>
        </template>
        <!-- Column: Telefone -->
        <template #cell(cell_phone)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top">{{ data.item.cell_phone }}</span>
          </div>
        </template>
        <!-- Column: Logradouro -->
        <template #cell(street)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.street }}</span>
          </div>
        </template>
        <!-- Column: Número -->
        <template #cell(number)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top">{{ data.item.number }}</span>
          </div>
        </template>
        <!-- Column: Complemento -->
        <template #cell(complement)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.complement }}</span>
          </div>
        </template>
        <!-- Column: Bairro -->
        <template #cell(district)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.district }}</span>
          </div>
        </template>
        <!-- Column: CEP -->
        <template #cell(cep)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top">{{ data.item.cep }}</span>
          </div>
        </template>
        <!-- Column: Município -->
        <template #cell(city)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.city }}</span>
          </div>
        </template>
        <!-- Column: UF -->
        <template #cell(state)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top">{{ data.item.state }}</span>
          </div>
        </template>
        <!-- Column: Empresa -->
        <template #cell(companies_name)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.companies_name }}</span>
          </div>
        </template>
        <!-- Column: Produto -->
        <template #cell(products_name)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.products_name }}</span>
          </div>
        </template>
        <!-- Column: Solução Financeira -->
        <template #cell(solutions_name)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.solutions_name }}</span>
          </div>
        </template>
        <!-- Column: Código do Contrato -->
        <template #cell(contract_id)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-center">{{ data.item.contract_id }}</span>
          </div>
        </template>
        <!-- Column: Status do Contrato -->
        <template #cell(contract_status)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.contract_status }}</span>
          </div>
        </template>
        <!-- Column: Data do Contrato -->
        <template #cell(contract_date)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top">{{ data.item.contract_date }}</span>
          </div>
        </template>
        <!-- Column: Data de Geração do Contrato -->
        <template #cell(contract_created_at)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top">{{ data.item.contract_created_at }}</span>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Exibindo {{ pageItemsFrom }} à {{ pageItemsTo }} de
              {{ totalItems }} itens</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              :value="currentPage"
              :total-rows="totalItems"
              :per-page="itemsPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="search"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BFormGroup,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import UserDetailPopover from "@/modules/shared/components/UserDetailPopover";
import AllSelect from "@/modules/shared/components/AllSelect";
export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BFormGroup,
    vSelect,
    UserDetailPopover,
    AllSelect,
  },
  setup() {
    return { toast: useToast() };
  },
  data() {
    return {
      loading: {
        consultants: false,
        franchises: false,
        search: false,
        export: false,
      },
      tableColumns: [
        { key: "name", label: "Nome", sortable: true },
        { key: "cpf", label: "CPF", sortable: true },
        { key: "email", label: "Email", sortable: true },
        { key: "cell_phone", label: "Telefone", sortable: true },
        { key: "street", label: "Logradouro", sortable: false },
        { key: "number", label: "Numero", sortable: false },
        { key: "complement", label: "Complemento", sortable: false },
        { key: "district", label: "Bairro", sortable: false },
        { key: "cep", label: "CEP", sortable: false },
        { key: "city", label: "Municipio", sortable: false },
        { key: "state", label: "UF", sortable: false },
        { key: "companies_name", label: "Empresa", sortable: true },
        { key: "products_name", label: "Produto", sortable: true },
        { key: "solutions_name", label: "Solução Financeira", sortable: true },
        { key: "contract_id", label: "Código do Contrato", sortable: true },
        { key: "contract_status", label: "Status do Contrato", sortable: true },
        { key: "contract_date", label: "Data do Contrato", sortable: true },
        { key: "contract_created_at", label: "Data de Geração do Contrato", sortable: true }
      ],
      order: undefined,
      sortField: null,
      rangeDate: undefined,
      currentPage: 1,
      itemsPerPage: 1,
      franchise: [],
      consultant: [],
    };
  },
  computed: {
    ...mapGetters({
      franchises: sharedTypes.FRANCHISES,
      consultants: sharedTypes.CONSULTANTS,
      contracts: types.CONTRACTS_AND_CUSTOMERS,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
    }),
    items: function () {
      return this.contracts?.data || [];
    },
    totalItems: function () {
      return this.contracts?.total || 0;
    },
    pageItemsFrom: function () {
      return this.contracts?.from || 0;
    },
    pageItemsTo: function () {
      return this.contracts?.to || 0;
    },
  },
  mounted() {
    this.itemsPerPage = this.initialItemsPerPage;
    this.search();
    this.loading.consultants = true;
    this.getConsultants()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os consultores para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.consultants = false;
      });
    this.loading.franchises = true;
    this.getFranchises()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os franqueados para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.franchises = false;
      });
  },
  methods: {
    ...mapActions({
      getFranchises: sharedTypes.GET_FRANCHISES,
      getConsultants: sharedTypes.GET_CONSULTANTS,
      searchContracts: types.SEARCH_CONTRACTS_AND_CUSTOMERS,
      exportContracts: types.EXPORT_CONTRACTS_AND_CUSTOMERS,
    }),
    goToContractDetails(row, index) {
      this.$router.push({
        name: "contract-details-summary",
        params: { id: row.contract_id },
      });
    },
    search(currentPage) {
      this.loading.search = true;
      this.currentPage = currentPage;
      this.searchContracts({
        franchise: this.franchise,
        consultant: this.consultant,
        limit: this.itemsPerPage,
        currentPage: Number.isInteger(currentPage) ? currentPage : 1,
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar os contratos. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.search = false;
        });
    },
    sort(event) {
      //clear in third click
      if (!event.sortDesc && this.sortField == event.sortBy) {
        this.order = undefined;
        this.sortField = null;
      } else {
        var direction = event.sortDesc ? " DESC" : " ASC";
        this.order = event.sortBy + direction;
        this.sortField = event.sortBy;
      }
      this.search();
    },
    clearSearch() {
      this.rangeDate = undefined;
      this.search(1);
    },
    exportData() {
      this.loading.export = true;
      this.exportContracts({
        order: this.order,
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar os contratos. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.export = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
