<template>
  <div>
    <b-table striped bordered hover :items="items" :fields="computedFields">
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'FranchiseTotalizer',
  props: {
    totalizer: Object,
    type: {
      type: String,
      default: 'franchise', 
    }
  },
  data() {
    return {
      items: [],
    }
  },
  computed: {
    computedFields() {
      const commonFields = [
        { key: 'QTDEnviadas', label: 'Qtde Enviadas', class: 'text-center' },
        { key: 'QTDRespondidas', label: 'Qtde Respondidas', class: 'text-center' },
        { key: 'percRespostas', label: '% de Respostas', class: 'text-center', formatter: (value) => `${value}%` },
        { key: 'NPS', label: 'NPS Atual', class: 'text-center', formatter: (value) => `${value}` },
      ];

      const consultantAdditionalFields = [
        { key: 'QTDPendencia', label: 'Qtde Pendência', class: 'text-center' },
        { key: 'QTDExpiradas', label: 'Qtde Expiradas', class: 'text-center' },
      ];
      if (this.type === 'franchise') {
        return commonFields;
      } else if (this.type === 'consultant') {
        // For consultant, insert additional fields before the last two common fields
        return [
          ...commonFields.slice(0, 2),
          ...consultantAdditionalFields,
          ...commonFields.slice(2),
        ];
      }
      return [];
    }
  },
  created() {
    this.fillData();
  },
  watch: {
        totalizer: {
          handler(newVal) {
              this.fillData();
          },
          deep: true
        }
    },
  methods: {
    fillData() {
      // Set default values for each field
      const defaultTotalizer = {
        surveys_sent: 0,
        surveys_answered: 0,
        surveys_pending: 0,
        surveys_expired: 0,
        percentage_of_answered_surveys: "0.00%",
        nps_average: 0
      };

      var data = this.totalizer || defaultTotalizer;
      if(!data.surveys_sent){
        data = defaultTotalizer
      }
      this.items = [{
        QTDEnviadas: data.surveys_sent,
        QTDRespondidas: data.surveys_answered,
        QTDPendencia: data.surveys_pending,
        QTDExpiradas: data.surveys_expired,
        percRespostas: parseFloat(data.percentage_of_answered_surveys || 0),
        NPS: parseFloat(data.nps_average), 
      }];
    }
  }
};
</script>

<style scoped>
.text-center th {
  text-align: center;
}
</style>
