<template>
  <div>
    <b-card-header class="pb-50">
      <h5>Filtros</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="6" class="mb-1">
          <b-form-group
            label="Despesa ou colaborador"
            label-for="searchExpenseName"
          >
            <b-form-input
              id="searchExpenseName"
              v-model="name"
              @keyup.enter="search"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" class="mb-1">
          <b-form-group
            label="Chave de pagamento"
            label-for="searchPaymentKey"
          >
            <b-form-input
              id="searchPaymentKey"
              v-model="paymentKey"
              @keyup.enter="search"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="1" class="mb-1">
          <b-form-group label="Ano" label-for="searchExpenseYear">
            <b-form-input 
              id="searchExpenseYear"
              type="number"
              min="2020"
              max="2099"
              step="1" 
              v-model="year" 
              @input="yearChanged"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="2" class="mb-1">
          <b-form-group label="Período" label-for="searchExpensePeriod">
            <flat-pickr
              id="searchExpensePeriod"
              v-model="period"
              class="form-control"
              :config="flatPickrConfig"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group label="Centro de custo" label-for="searchCostCenter">
            <v-select
              id="searchCostCenter"
              v-model="costCenter"
              multiple
              :reduce="(option) => option.id"
              :options="costCenters"
              :loading="loading.costCenters"
              label="name"
              @keyup.enter="search"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group label="Empresa" label-for="searchGroupCompany">
            <v-select
              id="searchGroupCompany"
              v-model="groupCompany"
              multiple
              :reduce="(option) => option.id"
              :options="companies"
              :loading="loading.companies"
              label="name"
              @keyup.enter="search"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group
            label="Tipo de pagamento"
            label-for="searchPaymentType"
          >
            <v-select
              id="searchPaymentType"
              v-model="paymentType"
              multiple
              :reduce="(option) => option.id"
              :options="paymentTypes"
              :loading="loading.paymentTypes"
              label="name"
              @keyup.enter="search"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" class="mb-1">
          <b-form-group
            label="Categoria de centro de custo"
            label-for="searchCostCenterCategory"
          >
            <v-select
              id="searchCostCenterCategory"
              multiple
              v-model="costCenterCategory"
              :reduce="(option) => option.id"
              :options="costCenterCategories"
              :loading="loading.costCenterCategories"
              label="name"
              @keyup.enter="search"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BSpinner,
  BPopover,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import flatPickr from "vue-flatpickr-component";
import ShortcutButtonsPlugin from "shortcut-buttons-flatpickr";
import todayButton from "@/helpers/todayButton";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BSpinner,
    BPopover,
    vSelect,
    flatPickr,
  },
  props: {
    search: Function,
    spinnerActive: Boolean,
    periodType: String,
  },
  data() {
    return {
      loading: {
        costCenters: false,
        companies: false,
        paymentTypes: false,
        costCenterCategories: false,
      },
      year: new Date().getFullYear(),
      flatPickrConfig: {
        altFormat: "j M Y",
        altInput: true,
        mode: "range",
        dateFormat: "Y-m-d",
        locale: Portuguese,
        plugins: [ShortcutButtonsPlugin(todayButton)],
      },
      name: "",
      period: undefined,
      costCenter: undefined,
      groupCompany: undefined,
      paymentType: undefined,
      paymentKey: "",
      costCenterCategory: undefined,
    };
  },
  setup() {
    const toast = useToast();
    const actualYear = new Date().getFullYear();
    return { toast, actualYear };
  },
  computed: {
    ...mapGetters({
      costCenters: sharedTypes.COST_CENTERS,
      companies: sharedTypes.GROUP_COMPANIES,
      paymentTypes: sharedTypes.PAYMENT_TYPES,
      costCenterCategories: sharedTypes.ALL_COST_CENTER_CATEGORIES,
    }),
  },
  mounted() {
    this.loadData();
  },
  methods: {
    ...mapActions({
      fetchCostCenters: sharedTypes.GET_COST_CENTERS,
      fetchCompanies: sharedTypes.GET_GROUP_COMPANIES,
      fetchPaymentTypes: sharedTypes.GET_PAYMENT_TYPES,
      fetchCostCenterCategories: sharedTypes.GET_ALL_COST_CENTER_CATEGORIES,
    }),
    ...mapMutations({
      mutateExpenseSearchYear: types.MUTATE_EXPENSES_SEARCH_YEAR
    }),
    loadData() {
      this.loadCostCenters();
      this.loadCompanies();
      this.loadPaymentTypes();
      this.loadCostCenterCategories();
    },
    loadCostCenters() {
      this.loading.costCenters = true;
      this.fetchCostCenters()
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os centros de custo. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.costCenters = false;
        });
    },
    loadCompanies() {
      this.loading.companies = true;
      this.fetchCompanies()
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar as empresas. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.companies = false;
        });
    },
    loadPaymentTypes() {
      this.loading.paymentTypes = true;
      this.fetchPaymentTypes()
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os tipos de pagamento. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.paymentTypes = false;
        });
    },
    loadCostCenterCategories() {
      this.loading.costCenterCategories = true;
      this.fetchCostCenterCategories()
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar as categorias de centro de custo. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.costCenterCategories = false;
        });
    },
    yearChanged(){
      this.mutateExpenseSearchYear(this.year)
    }
  },
};
</script>

<style lang="scss">
$themeColor: #f26237;
@import "~vue-slider-component/lib/theme/default.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.form-group {
  margin-bottom: 0;
}
</style>
