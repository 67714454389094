import {
  BUSINESS_SCHOOL_PROGRESS,
  MANAGE_ACTION,
  OVERDUE_SIGNATURE_REPORT,
  INACTIVE_PN_CONTRACTS_REPORT,
  READ_ACTION,
  INACTIVE_PN_CUSTOMERS_REPORT,
  CONTRACTS_AND_CUSTOMERS_REPORT,
  EPS_SUBSCRIPTIONS_AND_SOLUTIONS_REPORT
} from '@/constants/resources'
  


export default [
  {
    path: '/relatorio/assinaturas-inadimplentes',
    name: 'overdue-signature-report',
    component: () => import('@/modules/report/views/OverdueSignatureReport.vue'),
    meta: {
      resource: OVERDUE_SIGNATURE_REPORT,
      action: MANAGE_ACTION,
      pageTitle: 'Relatório de assinaturas inadimplentes'
    }
  },
  {
    path: '/relatorio/contratos-pns-inativos',
    name: 'inactive-pn-contracts-report',
    component: () => import('@/modules/report/views/InactiveConsultantContractsReport.vue'),
    meta: {
      resource: INACTIVE_PN_CONTRACTS_REPORT,
      action: READ_ACTION,
      pageTitle: 'Relatório de contratos de PNs inativos'
    }
  },
  {
    path: '/relatorio/clientes-pns-inativos',
    name: 'inactive-pn-customers-report',
    component: () => import('@/modules/report/views/InactiveConsultantCustomersReport.vue'),
    meta: {
      resource: INACTIVE_PN_CUSTOMERS_REPORT,
      action: READ_ACTION,
      pageTitle: 'Relatório de clientes de PNs inativos'
    }
  },
  {
    path: '/relatorio/clientes-e-contratos',
    name: 'contracts-and-customers',
    component: () => import('@/modules/report/views/ContractsAndCustomersReport.vue'),
    meta: {
      resource: CONTRACTS_AND_CUSTOMERS_REPORT,
      action: READ_ACTION,
      pageTitle: 'Relatório de clientes e contratos'
    }
  },
  {
    path: '/relatorio/eps-assinaturas-e-solucoes',
    name: 'eps-subscriptions-and-solutions',
    component: () => import('@/modules/report/views/EpsSubscriptionsAndSolutionsReport.vue'),
    meta: {
      resource: EPS_SUBSCRIPTIONS_AND_SOLUTIONS_REPORT,
      action: READ_ACTION,
      pageTitle: 'EPs, assinaturas e soluções'
    }
  },
  {
    path: '/relatorio/progresso-escola-negocios',
    name: 'business-school-progress',
    component: () => import('@/modules/report/views/BusinessSchoolProgressReport.vue'),
    meta: {
      resource: BUSINESS_SCHOOL_PROGRESS,
      action: READ_ACTION,
      pageTitle: 'Progresso escola de negócios'
    }
  },
]
  