<template>
  <div>
    <template v-if="loading">
      <div class="d-flex justify-content-center m-2">
        <b-spinner variant="primary" />
      </div>
    </template>

    <template v-else>
      <div class="campaign-container">
        <!-- Card maior para Campanhas -->
        <b-card title="Campanhas" class="campaign-card">
          <b-list-group>
            <b-list-group-item
              v-for="(campaign, index) in campaigns"
              :key="index"
              @click="selectCampaign(campaign)"
              class="campaign-item"
            >
              {{ campaign.name }}
            </b-list-group-item>
          </b-list-group>
        </b-card>

        <!-- Cards menores ao lado -->
        <div class="details-container" v-if="selectedCampaign">
          <b-card
            v-for="(detail, index) in selectedCampaign.details"
            :key="index"
            class="detail-card"
          >
            <b-card-title>{{ detail.title }}</b-card-title>
            <b-card-text>
              <strong>Pontos:</strong> {{ detail.points }}
            </b-card-text>
            <b-button
              class="text-details"
              variant="link"
              @click="openInfoModal(detail.title, detail.explanation)"
            >
              Ver detalhes &#10095;
            </b-button>
          </b-card>
        </div>

        <!-- Card maior com informações do DNA -->
        <b-card v-if="selectedCampaign && selectedCampaign.name === 'DNA'" class="info-card">
          <div v-for="(level, index) in dnaInfo.levels" :key="index">
            <h4 style="color: #f26237;">{{ level.name }}</h4>
            <p>
              <strong>Limite de pontos:</strong>
              <span v-if="level.points.range && Array.isArray(level.points.range)">
                {{ level.points.range[0] }} - {{ level.points.range[1] }}
              </span>
              <span v-else-if="level.points.total">
                {{ level.points.total }}
              </span>
            </p>
            <p><strong>Frequência:</strong> {{ level.points.frequency }}</p>
            <ul>
              <li v-for="(criterion, index) in level.criteria" :key="index">
                <strong>{{ criterion.description }}:</strong> {{ criterion.points }}
              </li>
            </ul>
          </div>
        </b-card>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as types from "../store/types";
import * as accountTypes from "@/modules/account/store/types";
import useAppConfig from "@core/app-config/useAppConfig";
import dnaInfo from "../../../json/info-pns-hunter-closes-franqueado.json";

export default {
  data() {
    return {
      campaigns: [
        {
          name: "DNA",
          details: [],
        },
        // Adicione outras campanhas aqui, se necessário
      ],
      selectedCampaign: null,
      loading: false,
      dnaInfo: dnaInfo,
    };
  },
  computed: {
    ...mapGetters({
      user: accountTypes.USER,
    }),
  },
  setup() {
    const { skin } = useAppConfig();

    return { skin };
  },
  methods: {
    ...mapActions({
      searchDnaHistory: types.GET_DNA_HISTORICAL,
    }),
    searchDna() {
      this.loading = true;

      const payload = {
        idConsultant: this.user?.appify_consultant_id,
      };

      return this.searchDnaHistory(payload)
        .then((resp) => {
          const data = resp.data.data;
          return [
            {
              title: "Aprendiz",
              points: data.apprentice_score,
              explanation: data.apprentice_explanation,
            },
            {
              title: "Focado",
              points: data.focused_score,
              explanation: data.focused_explanation,
            },
            {
              title: "Protagonista",
              points: data.protagonist_score,
              explanation: data.protagonist_explanation,
            },
            {
              title: "Campeão",
              points: data.champion_score,
              explanation: data.champion_explanation,
            },
          ];
        })
        .catch((err) => {
          console.error("Erro ao buscar DNA:", err);
          return [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async selectCampaign(campaign) {
      if (campaign.name === "DNA") {
        const details = await this.searchDna();
        campaign.details = details;
        console.log(dnaInfo.levels)
      }
      this.selectedCampaign = campaign;
    },
    openInfoModal(title, explanation) {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.toLocaleString("pt-BR", { month: "long" });
      const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1);

      const explanationJson = JSON.parse(explanation);

      const explanationHtml = `
        <div style="text-align: center;">
          <h3>${capitalizedMonth} de ${year}</h3>
          <div style="display: flex; flex-direction: column; align-items: center;">
            <div style="text-align: left; width: 100%; margin-bottom: 8px;">
              <strong>Pontuação:</strong> ${explanationJson.explanation[0].score}
            </div>
            <div style="text-align: left; width: 100%;">
              <strong>Explicação:</strong> ${explanationJson.explanation[0].explanation}
            </div>
          </div>
        </div>`;

      this.$swal({
        backdrop: true,
        title: `Informações sobre ${title}`,
        html: explanationHtml,
        icon: "info",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        allowEscapeKey: false,
        width: "40vw",
        heightAuto: false,
        background: `${this.skin === "dark" ? "#283046" : ""}`,
        customClass: {
          popup: "modal-height",
          confirmButton: "btn btn-primary",
          title: "text-primary",
          htmlContainer: `info-responsive ${
            this.skin === "dark" ? "text-white" : ""
          }`,
        },
      });
    }
  },
};
</script>

<style scoped>
.campaign-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.campaign-card {
  width: 100%;
  max-width: 300px;
}

.details-container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.detail-card {
  width: 45%;
}

.info-card {
  width: 100%;
  margin-top: 20px;
}
</style>
