<template>
  <b-card no-body>
    <b-card-header class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <b-card-title> Atividades recentes </b-card-title>
      </div>
      <b-button
        variant="primary"
        class="ms-auto"
        @click="downloadPDF"
        :disabled="downloading || loading"
      >
      <b-spinner v-if="downloading" small />
      <feather-icon v-else icon="DownloadIcon" class="mr-50" />
      <span>
        {{ downloading ? "Aguarde..." : "Baixar PDF" }}
      </span>
      </b-button>
    </b-card-header>

    <div v-if="loading" class="d-flex justify-content-center m-2">
      <b-spinner variant="primary" />
    </div>
    <div v-else>
      <!-- timeline -->
      <b-card-body>
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="scrollable-container scroll-area profile-scroller"
          tagname="div"
        >
          <div ref="timeline">
            <app-timeline class="mt-1">
              <template v-for="item in items">
                <app-timeline-item variant="primary">
                  <b-list-group>
                    <b-list-group-item class="item-no-click">
                      <h5 v-html="item.name"></h5>
                      <b-row>
                        <b-col md="8" v-html="item.description"></b-col>
                        <b-col class="text-right" md="4">{{
                          item.formatedDate
                        }}</b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-list-group>
                </app-timeline-item>
              </template>
            </app-timeline>
          </div>
        </vue-perfect-scrollbar>
        <!--/ timeline -->
      </b-card-body>
    </div>
  </b-card>
</template>

<script>
import _ from "lodash";
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BRow,
  BCardTitle,
  BListGroupItem,
  BListGroup,
  BSpinner,
} from "bootstrap-vue";
import { useToast } from "vue-toastification/composition";
import Ripple from "vue-ripple-directive";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapActions, mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

/* eslint-disable global-require */
export default {
  components: {
    BCard,
    BCol,
    BRow,
    BCardBody,
    BCardHeader,
    BCardTitle,
    AppTimeline,
    AppTimelineItem,
    BListGroupItem,
    BListGroup,
    BSpinner,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  setup() {
    return { toast: useToast() };
  },
  data() {
    return {
      loading: false,
      downloading: false,
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
        useBothWheelAxes: false,
        suppressScrollX: true,
      },
    };
  },
  computed: {
    ...mapGetters({
      customerTimelineEvents: types.CUSTOMER_TIMELINE_EVENTS,
      user: types.CUSTOMER,
    }),
    items: function () {
      var timeZone = "America/Sao_Paulo";
      var timeLine = this.customerTimelineEvents.map(function (element) {
        var utcDate = new Date(element.date);
        var options = {
          timeZone: timeZone,
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        };
        var brazilianDate = utcDate
          .toLocaleString("pt-BR", options)
          .replace(",", "");
        element.formatedDate = brazilianDate;
        return element;
      });
      return _.orderBy(
        timeLine,
        ['order', function (element) {
          return new Date(element.date);
        }],
        ['desc', 'desc']
      );
    },
  },
  mounted() {
    this.get();
  },
  methods: {
    ...mapActions({
      getCustomerTimelineEvents: types.GET_CUSTOMER_TIMELINE_EVENTS,
    }),
    get() {
      this.loading = true;
      this.getCustomerTimelineEvents(this.$route.params.id)
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar as atividades recentes. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async downloadPDF(){
      this.downloading = true;
      const element = this.$refs.timeline;
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
        compress: true, // Habilita compressão
      });
      const userName = this.user.name
      const pdfWidth = pdf.internal.pageSize.getWidth(); 
      const pdfHeight = pdf.internal.pageSize.getHeight(); 
      const margin = 10; 

      const headerFontSize = 14; 

      try {
        // Captura o elemento como um canvas
        const canvas = await html2canvas(element, {
          scale: 2,
          useCORS: true,
        });

        // Converte o canvas para uma imagem
        const imgWidth = pdfWidth - margin * 2;

        // Altura máxima de conteúdo por página (considera margens e cabeçalho na 1ª página)
        const pageHeight = pdfHeight - margin ;
        const pageHeightWithHeader = pdfHeight - margin * 2 - headerFontSize  - 10;

        let currentHeight = 0;
        let isFirstPage = true;

        while (currentHeight < canvas.height) {
          let canvasPageHeight = Math.min(pageHeight * canvas.width / pdfWidth, canvas.height - currentHeight);
          let textY = margin;
          if (isFirstPage) {
            // Adiciona o cabeçalho na primeira página
            pdf.setFontSize(headerFontSize);
            pdf.text("Linha do tempo de " + userName , pdfWidth / 2, textY, { align: "center" });

            // Calcula a altura da seção visível com cabeçalho
            canvasPageHeight = Math.min(pageHeightWithHeader * canvas.width / pdfWidth, canvas.height - currentHeight);
            isFirstPage = false;
          }
          const canvasPage = document.createElement("canvas");
          const context = canvasPage.getContext("2d");
          canvasPage.width = canvas.width;
          canvasPage.height = canvasPageHeight;

          context.drawImage(
            canvas,
            0,
            currentHeight,
            canvas.width,
            canvasPageHeight,
            0,
            0,
            canvas.width,
            canvasPageHeight
          );

          const pageData = canvasPage.toDataURL("image/png");
          pdf.addImage(
            pageData,
            "PNG",
            margin,
            textY + headerFontSize + 5,
            imgWidth,
            (canvasPageHeight * imgWidth) / canvas.width
          );

          currentHeight += canvasPageHeight;
          
          // Adiciona uma nova página se ainda houver conteúdo
          if (currentHeight < canvas.height) {
            pdf.addPage();
          }
        }
        pdf.save(`Linha do tempo ${userName}.pdf`);
      } catch (error) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao baixar o PDF. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
      this.downloading = false;
    }
  },
};
</script>
<style lang="scss" scoped>
.item-no-click:active {
  background-color: transparent !important;
}
.profile-scroller {
  max-height: 30rem;
}
</style>
