import * as types from './types'

export default {
  [types.MUTATE_CONSULTANTS_SEARCH_RESULT]: (state, payload) => {
    state.searchResult = payload
  },
  [types.MUTATE_DNA_HISTORICAL_SEARCH_RESULT]: (state, payload) => {
    state.searchResultDnaHistorical = payload
  },
  [types.MUTATE_IMPORTS_SEARCH_RESULT]: (state, payload) => {
    state.searchResultImports = payload
  },
  [types.MUTATE_IMPORT_HISTORY_SEARCH_RESULT]: (state, payload) => {
    state.searchResultImportHistory = payload
  },
  [types.CLEAR_DETAILS_GENERAL_INFORMATION]: (state, payload) => {
    state.details.generalInformation = undefined
  },
  [types.MUTATE_DETAILS_GENERAL_INFORMATION]: (state, payload) => {
    state.details.generalInformation = payload
  },
  [types.MUTATE_DETAILS_COMPANY_DATA]: (state, payload) => {
    state.details.companyData = payload
  },
  [types.MUTATE_DETAILS_NETWORK_DATA]: (state, payload) => {
    state.details.networkData = payload
  },
  [types.MUTATE_DIRECT_TEAM]: (state, payload) => {
    state.details.directTeam = payload
  },
  [types.MUTATE_NEW_CONSULTANT_SIDEBAR]: (state, payload) => {
    state.newConsultantSidebar = { ...state.newConsultantSidebar, ...payload }
  },
  [types.MUTATE_TRANSFERRED_CONSULTANTS]: (state, payload) => {
    state.transferredConsultants = payload
  },
  [types.MUTATE_TRANSFERABLE_CONSULTANTS]: (state, payload) => {
    state.transferableConsultants = payload
  },
  [types.MUTATE_CHANGE_CONSULTANT_ROLE_SIDEBAR]: (state, payload) => {
    state.changeConsultantRoleSidebar = { ...state.changeConsultantRoleSidebar, ...payload }
  },
  [types.MUTATE_AFFILIATION_TYPES]: (state, payload) => {
    state.affiliationTypes = payload
  },
  [types.MUTATE_NEW_DNA_CAMPAIGN_SIDEBAR]: (state, payload) => {
    state.newDnaCampaignSidebar = { ...state.newDnaCampaignSidebar, ...payload }
  },
}
