// Getters
export const OVERDUE_SIGNATURES = 'report/OVERDUE_SIGNATURES'
export const INACTIVE_PN_CONTRACTS = 'report/INACTIVE_PN_CONTRACTS'
export const INACTIVE_PN_CUSTOMERS = 'report/INACTIVE_PN_CUSTOMERS'
export const CONTRACTS_AND_CUSTOMERS = 'report/CONTRACTS_AND_CUSTOMERS'
export const EPS_SUBSCRIPTIONS_AND_SOLUTIONS = 'report/EPS_SUBSCRIPTIONS_AND_SOLUTIONS'

// Mutations
export const MUTATE_OVERDUE_SIGNATURES = 'report/MUTATE_OVERDUE_SIGNATURES'
export const MUTATE_INACTIVE_PN_CONTRACTS = 'report/MUTATE_INACTIVE_PN_CONTRACTS'
export const MUTATE_INACTIVE_PN_CUSTOMERS = 'report/MUTATE_INACTIVE_PN_CUSTOMERS'
export const MUTATE_CONTRACTS_AND_CUSTOMERS = 'report/MUTATE_CONTRACTS_AND_CUSTOMERS'
export const MUTATE_EPS_SUBSCRIPTIONS_AND_SOLUTIONS = 'report/MUTATE_EPS_SUBSCRIPTIONS_AND_SOLUTIONS'

// Actions
export const SEARCH_OVERDUE_SIGNATURES = 'report/SEARCH_OVERDUE_SIGNATURES'
export const EXPORT_OVERDUE_SIGNATURES = 'report/EXPORT_OVERDUE_SIGNATURES'
export const SEARCH_INACTIVE_PN_CONTRACTS = 'report/SEARCH_INACTIVE_PN_CONTRACTS'
export const EXPORT_INACTIVE_PN_CONTRACTS = 'report/EXPORT_INACTIVE_PN_CONTRACTS'
export const SEARCH_INACTIVE_PN_CUSTOMERS = 'report/SEARCH_INACTIVE_PN_CUSTOMERS'
export const EXPORT_INACTIVE_PN_CUSTOMERS = 'report/EXPORT_INACTIVE_PN_CUSTOMERS'
export const EXPORT_CONTRACTS_PAYMENTS_COMISSIONS = "report/EXPORT_CONTRACT_PAYMENT_COMISSIONS";
export const EXPORT_INVOICES_SUMMARY = "report/EXPORT_INVOICES_SUMMARY"
export const EXPORT_AGENCY_COMMISSIONS = "report/EXPORT_AGENCY_COMMISSIONS"
export const SEARCH_CONTRACTS_AND_CUSTOMERS = 'report/SEARCH_CONTRACTS_AND_CUSTOMERS'
export const EXPORT_CONTRACTS_AND_CUSTOMERS = 'report/EXPORT_CONTRACTS_AND_CUSTOMERS'
export const SEARCH_EPS_SUBSCRIPTIONS_AND_SOLUTIONS = 'report/SEARCH_EPS_SUBSCRIPTIONS_AND_SOLUTIONS'
export const EXPORT_EPS_SUBSCRIPTIONS_AND_SOLUTIONS = 'report/EXPORT_EPS_SUBSCRIPTIONS_AND_SOLUTIONS'