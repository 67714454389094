import * as types from './types'

export default {
  [types.MUTATE_OVERDUE_SIGNATURES]: (state, payload) => {
    state.overdueSignatures = payload
  },
  [types.MUTATE_INACTIVE_PN_CONTRACTS]: (state, payload) => {
    state.inactivePnContracts = payload
  },
  [types.MUTATE_INACTIVE_PN_CUSTOMERS]: (state, payload) => {
    state.inactivePnCustomers = payload
  },
  [types.MUTATE_CONTRACTS_AND_CUSTOMERS]: (state, payload) => {
    state.contractAndCustomers = payload
  },
  [types.MUTATE_EPS_SUBSCRIPTIONS_AND_SOLUTIONS]: (state, payload) => {
    state.epsSubscriptionsAndSolutions = payload
  }
}
