import {
  READ_ACTION,
  CREATE_ACTION,
  CUSTOMER_RESOURCE,
  CAMPAIGN,
  LEAD_CUSTOMER,
  LEAD_PN,
  APPOINTMENT,
  TRANSFER_LEAD,
  MANAGE_ACTION,
  TRANSFER_CUSTOMER,
  PENDING_INTERVIEWS
} from "@/constants/resources";

import {
  PROFILE_TYPE_CONSULTANT,
  PROFILE_TYPE_CUSTOMER,
  PROFILE_TYPE_LEAD,
} from "@/constants/profile";

import { leadCustomer, customer, appointment, leadPn, transferCustomers } from "@/helpers/modalInfo";

export default [
  {
    path: "/leads-clientes",
    name: "leads-customers-list",
    component: () => import("@/modules/lead/views/LeadsCustomersList.vue"),
    meta: {
      resource: LEAD_CUSTOMER,
      action: READ_ACTION,
      pageTitle: "Leads clientes",
      info: leadCustomer,
    },
  },
  {
    path: "/potenciais-pns",
    name: "potential-partners-list",
    component: () => import("@/modules/lead/views/PotentialPartnersList.vue"),
    meta: {
      resource: LEAD_PN,
      action: READ_ACTION,
      pageTitle: "Leads PNs",
      info: leadPn,
    } 
  },
  {
    path: "/lead/:id/detalhes",
    name: "lead-profile",
    component: () => import("@/modules/profile/views/Profile.vue"),
    meta: {
      resource: LEAD_CUSTOMER,
      action: READ_ACTION,
      pageTitle: "Detalhes do lead cliente",
    },
    props: {
      profileType: PROFILE_TYPE_LEAD,
    },
  },
  {
    path: "/lead-pn/:id/detalhes",
    name: "lead-pn-profile",
    component: () => import("@/modules/profile/views/Profile.vue"),
    meta: {
      resource: LEAD_PN,
      action: READ_ACTION,
      pageTitle: "Detalhes do lead PN",
    },
    props: {
      profileType: PROFILE_TYPE_CONSULTANT,
    },
  },
  {
    path: "/cliente/:id/detalhes",
    name: "customer-profile",
    component: () => import("@/modules/profile/views/Profile.vue"),
    meta: {
      resource: CUSTOMER_RESOURCE,
      action: READ_ACTION,
      pageTitle: "Detalhes do cliente",
    },
    props: {
      profileType: PROFILE_TYPE_CUSTOMER,
    },
  },
  {
    path: "/clientes",
    name: "customers-list",
    component: () => import("@/modules/customer/views/CustomersList.vue"),
    meta: {
      resource: CUSTOMER_RESOURCE,
      action: READ_ACTION,
      pageTitle: "Clientes",
      info: customer,
    },
  },
  {
    path: "/compromissos",
    name: "appointments",
    component: () => import("@/modules/appointment/views/AppointmentsList.vue"),
    meta: {
      resource: APPOINTMENT,
      action: CREATE_ACTION,
      pageTitle: "Compromissos",
      info: appointment,
    },
  },
  {
    path: "/ef-sem-ep",
    name: "pending-interviews-data-studio",
    component: () =>
      import("@/modules/customer/views/PendingInterviewsDataStudio.vue"),
    meta: {
      resource: PENDING_INTERVIEWS,
      action: READ_ACTION,
      pageTitle: "EFs sem EP",
    },
  },
  {
    path: "/transferir-lead",
    name: "leads-transfer",
    component: () => import("@/modules/lead/views/LeadsTransferList.vue"),
    meta: {
      resource: TRANSFER_LEAD,
      action: MANAGE_ACTION,
      pageTitle: "Transferir leads",
    },
  },
  {
    path: "/transferir-cliente",
    name: "customers-transfer",
    component: () =>
      import("@/modules/customer/views/CustomerTransferList.vue"),
    meta: {
      resource: TRANSFER_CUSTOMER,
      action: MANAGE_ACTION,
      pageTitle: "Transferir clientes",
      info: transferCustomers
    },
  },
  {
    path: "/captacao-leads",
    name: "lead-capture",
    component: () =>
      import("@/modules/lead/views/LeadCaptureList.vue"),
    meta: {
      resource: CAMPAIGN,
      action: READ_ACTION,
      pageTitle: "Captação de Leads"
    },
  },
];
