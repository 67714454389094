<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-card no-body class="card-statistics">
                    <b-card-header class="p-2">
                        <b-card-title>Resumo</b-card-title>
                    </b-card-header>
                    <b-card-body class="px-3">
                        <b-row class="justify-content-around justify-content-lg-between flex-wrap">
                            <b-col cols="auto" class="mt-1 text-center">
                                <b-media no-body>
                                    <b-media-body class="my-auto">
                                        <span class="totalizer-title">Receita</span>
                                        <br>
                                        <h4 class="font-weight-bolder mb-0">
                                            {{ totalIncome | toCurrency }}
                                        </h4>
                                    </b-media-body>
                                </b-media>
                            </b-col>
                            <b-col cols="auto" class="mt-1 text-center">
                                <b-media no-body>
                                    <b-media-body class="my-auto">
                                        <span class="totalizer-title">Despesas previstas</span>
                                        <br>
                                        <h4 class="font-weight-bolder mb-0">
                                            {{ predictedToPay | toCurrency }}
                                        </h4>
                                    </b-media-body>
                                </b-media>
                            </b-col>
                            <b-col cols="auto" class="mt-1 text-center">
                                <b-media no-body>
                                    <b-media-body class="my-auto">
                                        <span class="totalizer-title">Saldo Previsto</span>
                                        <br>
                                        <h4 class="font-weight-bolder mb-0">
                                            {{ predictedBalance | toCurrency }}
                                        </h4>
                                    </b-media-body>
                                </b-media>
                            </b-col>
                            <b-col cols="auto" class="mt-1 text-center">
                                <b-media no-body>
                                    <b-media-body class="my-auto">
                                        <span class="totalizer-title">Contas a pagar</span>
                                        <br>
                                        <h4 class="font-weight-bolder mb-0">
                                            {{ totalToPay | toCurrency }}
                                        </h4>
                                    </b-media-body>
                                </b-media>
                            </b-col>
                            <b-col cols="auto" class="mt-1 text-center">
                                <b-media no-body>
                                    <b-media-body class="my-auto">
                                        <span class="totalizer-title">Saldo</span>
                                        <br>
                                        <h4 class="font-weight-bolder mb-0">
                                            {{ remainingAmount | toCurrency }}
                                        </h4>
                                    </b-media-body>
                                </b-media>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col cols="12">
                <b-card no-body class="card-statistics">
                    <b-card-header class="p-2">
                        <b-card-title>Totalizador</b-card-title>
                    </b-card-header>
                    <b-card-body class="px-1">
                        <b-table 
                            class="position-relative text-center" 
                            :items="totalizerItems" 
                            responsive 
                            :fields="tableFields" 
                            :no-local-sorting="true"
                        >
                            <!-- Column: Categoria -->
                            <template #cell(category)="data">
                                <div class="text-wrap">
                                    <span class="align-text-top text-capitalize">{{ data.item.category }}</span>
                                </div>
                            </template>

                            <!-- Column: Contas a pagar -->
                            <template #cell(to_pay_amount)="data">
                                <div class="text-wrap">
                                    <span class="align-text-top text-capitalize">{{ data.item.to_pay_amount | toCurrency }}</span>
                                </div>
                            </template>

                            <!-- Column: Solicitado -->
                            <template #cell(requested_amount)="data">
                                <div class="text-wrap">
                                    <span class="align-text-top text-capitalize">{{ data.item.requested_amount | toCurrency }}</span>
                                </div>
                            </template>

                            <!-- Column: Falta solicitar -->
                            <template #cell(not_paid_amount)="data">
                                <div class="text-wrap">
                                    <span class="align-text-top text-capitalize">{{ data.item.not_paid_amount | toCurrency }}</span>
                                </div>
                            </template>

                            <!-- Column: Pago -->
                            <template #cell(paid_amount)="data">
                                <div class="text-wrap">
                                    <span class="align-text-top text-capitalize">{{ data.item.paid_amount | toCurrency }}</span>
                                </div>
                            </template>

                            <!-- Column: Saldo -->
                            <template #cell(remaining_amount)="data">
                                <div class="text-wrap">
                                    <span class="align-text-top text-capitalize">{{ data.item.remaining_amount | toCurrency }}</span>
                                </div>
                            </template>
                        </b-table>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {
    BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody, BMedia, BMediaBody, BTable
} from 'bootstrap-vue';

export default {
    components: {
        BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody, BMedia, BMediaBody, BTable
    },
    props: {
        totalizers: Object
    },
    data() {
        return {
            tableFields: [
                { key: 'category', label: 'Categoria' },
                { key: 'to_pay_amount', label: 'Contas a pagar' },
                { key: 'requested_amount', label: 'Solicitado' },
                { key: 'not_paid_amount', label: 'Falta solicitar' },
                { key: 'paid_amount', label: 'Pago' },
                { key: 'remaining_amount', label: 'Saldo' },
            ]
        };
    },
    computed: {
        totalizerItems: function () {
            return [
                {
                    category: 'PNs', 
                    to_pay_amount: this.totalizers?.pn_to_pay_amount || 0, 
                    requested_amount: this.totalizers?.pn_requested_amount || 0, 
                    not_paid_amount: this.totalizers?.pn_not_paid_amount || 0, 
                    paid_amount: this.totalizers?.pn_paid_amount || 0, 
                    remaining_amount:  this.totalizers?.pn_remaining_amount || 0
                },
                {
                    category: 'Colaboradores', 
                    to_pay_amount: this.totalizers?.employee_to_pay_amount || 0, 
                    requested_amount: this.totalizers?.employee_requested_amount || 0, 
                    not_paid_amount: this.totalizers?.employee_not_paid_amount || 0, 
                    paid_amount: this.totalizers?.employee_paid_amount || 0, 
                    remaining_amount: this.totalizers?.employee_remaining_amount || 0
                },
                {
                    category: 'Despesas', 
                    to_pay_amount: this.totalizers?.expense_to_pay_amount || 0, 
                    requested_amount: this.totalizers?.expense_requested_amount || 0, 
                    not_paid_amount: this.totalizers?.expense_not_paid_amount || 0, 
                    paid_amount: this.totalizers?.expense_paid_amount || 0, 
                    remaining_amount: this.totalizers?.expense_remaining_amount || 0
                },
                {
                    category: 'Total', 
                    to_pay_amount: (this.totalizers?.pn_to_pay_amount || 0) + 
                        (this.totalizers?.employee_to_pay_amount || 0) +
                        (this.totalizers?.expense_to_pay_amount || 0),
                    requested_amount: (this.totalizers?.pn_requested_amount || 0) + 
                        (this.totalizers?.employee_requested_amount || 0) +
                        (this.totalizers?.expense_requested_amount || 0),
                    not_paid_amount: (this.totalizers?.pn_not_paid_amount || 0) +
                        (this.totalizers?.employee_not_paid_amount || 0) +
                        (this.totalizers?.expense_not_paid_amount || 0),
                    paid_amount: (this.totalizers?.pn_paid_amount || 0) + 
                        (this.totalizers?.employee_paid_amount || 0) +
                        (this.totalizers?.expense_paid_amount || 0),
                    remaining_amount: (this.totalizers?.pn_remaining_amount || 0) + 
                        (this.totalizers?.employee_remaining_amount || 0) +
                        (this.totalizers?.expense_remaining_amount || 0)
                }
            ];
        },
        totalToPay: function() {
            return (this.totalizers?.pn_requested_amount || 0) 
                + (this.totalizers?.employee_requested_amount || 0)
                + (this.totalizers?.expense_requested_amount || 0);
        },
        predictedToPay: function() {
            return (this.totalizers?.pn_to_pay_amount || 0) 
                + (this.totalizers?.employee_to_pay_amount || 0)
                + (this.totalizers?.expense_to_pay_amount || 0);
        },
        predictedBalance: function() {
            return (this.totalizers?.total_income - this.predictedToPay);
        },
        totalCommissions: function() {
            return this.totalizers?.total_commissions || 0;
        },
        totalIncome: function() {
            return this.totalizers?.total_income || 0;
        },
        totalFinancialAdjustments: function() {
            return this.totalizers?.total_financial_adjustments || 0;
        },
        remainingAmount: function() {
            return (this.totalizers?.total_income - this.totalToPay);
        }
    }
};
</script>

<style lang="scss" scoped>
.totalizer-title {
    font-weight: 500;
    font-size: 1.2rem;
}
</style>