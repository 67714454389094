<template>
  <div>
    <b-sidebar
      id="sidebar-edit-contract"
      sidebar-class="sidebar-lg"
      :visible="newDnaCampaignSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @shown="onShow"
      @change="(val) => mutateNewDnaCampaignSidebar({ visible: val })"
      @hidden="clear"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="planning-sidebar-header px-2 py-1"
        >
          <h4>Adicionar nova campanha de DNA</h4>
        </div>
        <template v-if="loading.form">
          <b-row class="justify-content-md-center mt-2">
            <b-spinner
              big
              variant="primary"
            />
          </b-row>
        </template>
        <div
          v-else
          class="m-2"
        >
          <b-row>
            <b-col cols="12" class="mt-1">
              <b-form-group label="Selecione o Ano/Mês" label-for="month-year-select">
                <b-form-select
                  id="month-year-select"
                  v-model="selectedDate"
                  :options="months"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled>Selecione...</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="mt-1"
            >
              <b-form-group
                label="Missão Aprendiz"
                label-for="risk-compesation"
              >
                <b-form-input
                  id="amount"
                  v-model="apprentice"
                  type="number"
                  min="0"
                  max="100"
                  :disabled="isPreviousMonth"
                  @input="validateInputs('apprentice', 100)"
                />
                <div class="invalid-feedback">
                  <span v-if="!isValid">
                    O valor deve estar entre 0 e 100.
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="6"
              class="mt-1"
            >
              <b-form-group
                label="Missão Focado"
                label-for="risk-compesation"
              >
                <small>Semana 1</small>
                <b-form-input
                  id="amount"
                  v-model="focusedWeek1"
                  :disabled="focusedWeek1Disabled || isPreviousMonth"
                  type="number"
                  min="0"
                  max="maxPointsFocused"
                  @input="validateInputsWeekFocused('focusedWeek1')"
                />
                <div class="invalid-feedback">
                  <span v-if="!isValid">
                    O valor deve estar entre 0 e 100.
                  </span>
                </div>
              </b-form-group>
              <b-form-group label-for="risk-compesation">
                <small>Semana 2</small>
                <b-form-input
                  id="amount"
                  v-model="focusedWeek2"
                  :disabled="focusedWeek2Disabled || isPreviousMonth"
                  type="number"
                  min="0"
                  max="maxPointsFocused"
                  @input="validateInputsWeekFocused('focusedWeek2')"
                />
                <div class="invalid-feedback">
                  <span v-if="!isValid">
                    O valor deve estar entre 0 e 100.
                  </span>
                </div>
              </b-form-group>
              <b-form-group label-for="risk-compesation">
                <small>Semana 3</small>
                <b-form-input
                  id="amount"
                  v-model="focusedWeek3"
                  :disabled="focusedWeek3Disabled || isPreviousMonth"
                  type="number"
                  min="0"
                  max="maxPointsFocused"
                  @input="validateInputsWeekFocused('focusedWeek3')"
                />
                <div class="invalid-feedback">
                  <span v-if="!isValid">
                    O valor deve estar entre 0 e 100.
                  </span>
                </div>
              </b-form-group>
              <b-form-group label-for="risk-compesation">
                <small>Semana 4</small>
                <b-form-input
                  id="amount"
                  v-model="focusedWeek4"
                  :disabled="focusedWeek4Disabled || isPreviousMonth"
                  type="number"
                  min="0"
                  max="maxPointsFocused"
                  @input="validateInputsWeekFocused('focusedWeek4')"
                />
                <div class="invalid-feedback">
                  <span v-if="!isValid">
                    O valor deve estar entre 0 e 100.
                  </span>
                </div>
              </b-form-group>
              <b-form-group
                v-if="!focusedWeek5Disabled"
                label-for="risk-compesation"
              >
                <small>Semana 5</small>
                <b-form-input
                  id="amount"
                  v-model="focusedWeek5"
                  :disabled="isPreviousMonth"
                  type="number"
                  min="0"
                  max="maxPointsFocused"
                  @input="validateInputsWeekFocused('focusedWeek5')"
                />
                <div class="invalid-feedback">
                  <span v-if="!isValid">
                    O valor deve estar entre 0 e 100.
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="6"
              class="mt-1"
            >
              <b-form-group
                label="Missão Protagonisca"
                label-for="risk-compesation"
              >
                <small>Semana 1</small>
                <b-form-input
                  id="amount"
                  v-model="protagonistWeek1"
                  :disabled="protagonistWeek1Disabled || isPreviousMonth"
                  type="number"
                  min="0"
                  max="maxPointsProtagonist"
                  @input="validateInputsWeekProtagonist('protagonistWeek1')"
                />
                <div class="invalid-feedback">
                  <span v-if="!isValid">
                    O valor deve estar entre 0 e 100.
                  </span>
                </div>
              </b-form-group>
              <b-form-group label-for="risk-compesation">
                <small>Semana 2</small>
                <b-form-input
                  id="amount"
                  v-model="protagonistWeek2"
                  :disabled="protagonistWeek2Disabled || isPreviousMonth"
                  type="number"
                  min="0"
                  max="maxPointsProtagonist"
                  @input="validateInputsWeekProtagonist('protagonistWeek2')"
                />
                <div class="invalid-feedback">
                  <span v-if="!isValid">
                    O valor deve estar entre 0 e 100.
                  </span>
                </div>
              </b-form-group>
              <b-form-group label-for="risk-compesation">
                <small>Semana 3</small>
                <b-form-input
                  id="amount"
                  v-model="protagonistWeek3"
                  :disabled="protagonistWeek3Disabled || isPreviousMonth"
                  type="number"
                  min="0"
                  max="maxPointsProtagonist"
                  @input="validateInputsWeekProtagonist('protagonistWeek3')"
                />
                <div class="invalid-feedback">
                  <span v-if="!isValid">
                    O valor deve estar entre 0 e 100.
                  </span>
                </div>
              </b-form-group>
              <b-form-group label-for="risk-compesation">
                <small>Semana 4</small>
                <b-form-input
                  id="amount"
                  v-model="protagonistWeek4"
                  :disabled="protagonistWeek4Disabled || isPreviousMonth"
                  type="number"
                  min="0"
                  max="maxPointsProtagonist"
                  @input="validateInputsWeekProtagonist('protagonistWeek4')"
                />
                <div class="invalid-feedback">
                  <span v-if="!isValid">
                    O valor deve estar entre 0 e 100.
                  </span>
                </div>
              </b-form-group>
              <b-form-group
                v-if="!protagonistWeek5Disabled"
                label-for="risk-compesation"
              >
                <small>Semana 5</small>
                <b-form-input
                  id="amount"
                  v-model="protagonistWeek5"
                  type="number"
                  :disabled="isPreviousMonth"
                  min="0"
                  max="maxPointsProtagonist"
                  @input="validateInputsWeekProtagonist('protagonistWeek5')"
                />
                <div class="invalid-feedback">
                  <span v-if="!isValid">
                    O valor deve estar entre 0 e 100.
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="mt-1"
            >
              <b-form-group
                label="Missão Campeão"
                label-for="risk-compesation"
              >
                <b-form-input
                  id="amount"
                  v-model="champion"
                  :disabled="isPreviousMonth"
                  type="number"
                  min="0"
                  max="50"
                  @input="validateInputs('champion', 50)"
                />
                <div class="invalid-feedback">
                  <span v-if="!isValid">
                    O valor deve estar entre 0 e 50.
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="mt-1"
            >
              <b-form-group label-for="certified-pnhunter">
                <b-form-checkbox
                  id="certified-pnhunter"
                  v-model="certifiedPnhunter"
                  :disabled="isPreviousMonth"
                >
                  Certificado de PNHunter
                </b-form-checkbox>
              </b-form-group>
              <b-form-group label-for="certified-pnclouser">
                <b-form-checkbox
                  id="certified-pnclouser"
                  v-model="certifiedPnclouser"
                  :disabled="isPreviousMonth"
                >
                  Certificado de PNClouser
                </b-form-checkbox>
              </b-form-group>
              <b-form-group label-for="certified-p1">
                <b-form-checkbox
                  id="certified-p1"
                  v-model="certifiedP1"
                  :disabled="isPreviousMonth"
                >
                  Certificado P1
                </b-form-checkbox>
              </b-form-group>
              <b-form-group label-for="certified-p2">
                <b-form-checkbox
                  id="certified-p2"
                  v-model="certifiedP2"
                  :disabled="isPreviousMonth"
                >
                  Certificado P2
                </b-form-checkbox>
              </b-form-group>
              <b-form-group label-for="certified-p3">
                <b-form-checkbox
                  id="certified-p3"
                  v-model="certifiedP3"
                  :disabled="isPreviousMonth"
                >
                  Certificado P3
                </b-form-checkbox>
              </b-form-group>
              <b-form-group label-for="certified-p4">
                <b-form-checkbox
                  id="certified-p4"
                  v-model="certifiedP4"
                  :disabled="isPreviousMonth"
                >
                  Certificado P4
                </b-form-checkbox>
              </b-form-group>
              <b-form-group label-for="certified-p5">
                <b-form-checkbox
                  id="certified-p5"
                  v-model="certifiedP5"
                  :disabled="isPreviousMonth"
                >
                  Certificado P5
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="mt-1"
            >
              <b-form-group
                label="Presença na reunião semanal 'Franqueados/PNG'"
                label-for="risk-compesation"
              >
                <b-form-input
                  id="amount"
                  v-model="meetingPresence"
                  :disabled="isPreviousMonth"
                  type="number"
                  min="0"
                  max="10"
                  @input="validateInputs('meetingPresence', 10)"
                />
                <div class="invalid-feedback">
                  <span v-if="!isValid">
                    O valor deve estar entre 0 e 10.
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="mt-1"
            >
              <b-form-group
                label="Presença na reunião mensal 'Franqueados/Acionista'"
                label-for="risk-compesation"
              >
                <b-form-input
                  id="amount"
                  v-model="monthlyMeetingPresence"
                  :disabled="isPreviousMonth"
                  type="number"
                  min="0"
                  max="25"
                  @input="validateInputs('monthlyMeetingPresence', 25)"
                />
                <div class="invalid-feedback">
                  <span v-if="!isValid">
                    O valor deve estar entre 0 e 25.
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="mt-1"
            >
              <b-form-group
                label="PEC - Programa de estudo contínuo"
                label-for="risk-compesation"
              >
                <b-form-input
                  id="amount"
                  v-model="pec"
                  :disabled="isPreviousMonth"
                  type="number"
                  min="0"
                  max="100"
                  @input="validateInputs('pec', 100)"
                />
                <div class="invalid-feedback">
                  <span v-if="!isValid">
                    O valor deve estar entre 0 e 100.
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="mt-1"
            >
              <b-form-group
                label="Certificados externos"
                label-for="risk-compesation"
              >
                <b-form-input
                  id="amount"
                  v-model="externalCertificate"
                  :disabled="isPreviousMonth"
                  type="number"
                  min="0"
                  max="100"
                  @input="validateInputs('externalCertificate', 100)"
                />
                <div class="invalid-feedback">
                  <span v-if="!isValid">
                    O valor deve estar entre 0 e 100.
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="mt-1"
            >
              <b-form-group
                label="Média do time 4 WS parceiros do mês assistidos"
                label-for="risk-compesation"
              >
                <b-form-input
                  id="amount"
                  v-model="teamAverage4WsPartners"
                  :disabled="isPreviousMonth"
                  type="number"
                  min="0"
                  max="100"
                  @input="validateInputs('teamAverage4WsPartners', 100)"
                />
                <div class="invalid-feedback">
                  <span v-if="!isValid">
                    O valor deve estar entre 0 e 100.
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="mt-1"
            >
              <b-form-group
                label="Aprendizagem continuada"
                label-for="risk-compesation"
              >
                <b-form-input
                  id="amount"
                  v-model="continuousLearning"
                  :disabled="isPreviousMonth"
                  type="number"
                  min="0"
                  max="100"
                  @input="validateInputs('continuousLearning', 100)"
                />
                <div class="invalid-feedback">
                  <span v-if="!isValid">
                    O valor deve estar entre 0 e 100.
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="mt-1"
            >
              <b-form-group
                label="Média do time 4 WS"
                label-for="risk-compesation"
              >
                <b-form-input
                  id="amount"
                  v-model="averageTeam4Ws"
                  :disabled="isPreviousMonth"
                  type="number"
                  min="0"
                  max="100"
                  @input="validateInputs('averageTeam4Ws', 100)"
                />
                <div class="invalid-feedback">
                  <span v-if="!isValid">
                    O valor deve estar entre 0 e 100.
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="mt-1"
            >
              <b-form-group
                label="Débitos"
                label-for="risk-compesation"
              >
                <b-form-input
                  id="amount"
                  v-model="debits"
                  :disabled="isPreviousMonth"
                  type="number"
                  min="0"
                  max="100"
                  @input="validateInputs('debits', 100)"
                />
                <div class="invalid-feedback">
                  <span v-if="!isValid">
                    O valor deve estar entre 0 e 100.
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="mt-1"
            >
              <b-form-group
                label="PN's responderem o CSAT"
                label-for="risk-compesation"
              >
                <b-form-input
                  id="amount"
                  v-model="pnsRespondedCsat"
                  :disabled="isPreviousMonth"
                  type="number"
                  min="0"
                  max="100"
                  @input="validateInputs('pnsRespondedCsat', 100)"
                />
                <div class="invalid-feedback">
                  <span v-if="!isValid">
                    O valor deve estar entre 0 e 100.
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="mt-1"
            >
              <b-form-group
                label="PN's aprovados no WS do CEO"
                label-for="risk-compesation"
              >
                <b-form-input
                  id="amount"
                  v-model="pnsApprovedWsCeo"
                  :disabled="isPreviousMonth"
                  type="number"
                  min="0"
                  max="150"
                  @input="validateInputs('pnsApprovedWsCeo', 150)"
                />
                <div class="invalid-feedback">
                  <span v-if="!isValid">
                    O valor deve estar entre 0 e 150.
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="mt-1"
            >
              <b-form-group
                label="8 REC de CL/EF"
                label-for="risk-compesation"
              >
                <b-form-input
                  id="amount"
                  v-model="recClEf"
                  :disabled="isPreviousMonth"
                  type="number"
                  min="0"
                  max="15"
                  @input="validateInputs('recClEf', 15)"
                />
                <div class="invalid-feedback">
                  <span v-if="!isValid">
                    O valor deve estar entre 0 e 15.
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="mt-1"
            >
              <b-form-group
                label="3 REC de PN/EF"
                label-for="risk-compesation"
              >
                <b-form-input
                  id="amount"
                  v-model="recPnEf"
                  :disabled="isPreviousMonth"
                  type="number"
                  min="0"
                  max="15"
                  @input="validateInputs('recPnEf', 15)"
                />
                <div class="invalid-feedback">
                  <span v-if="!isValid">
                    O valor deve estar entre 0 e 15.
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div class="planning-sidebar-bottom px-1">
          <b-col
            cols="12"
            md="6"
            class="mt-2 planing-sidebar-button"
          >
            <b-button
              variant="outline-primary"
              @click="hide"
            > Fechar
            </b-button>
          </b-col>
          <b-col
            v-if="!isPreviousMonth"
            cols="12"
            md="6"
            class="mt-2 planing-sidebar-button"
          >
            <b-button
              variant="primary"
              @click="save"
            > Salvar
            </b-button>
          </b-col>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BButton,
  BFormInput,
  BFormTextarea,
  BCol,
  BRow,
  BSpinner,
} from 'bootstrap-vue'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Ripple from 'vue-ripple-directive'
import useVuelidate from '@vuelidate/core'
import { required, between, requiredIf } from '@vuelidate/validators'
import { floatToMoney, moneyToFloat } from '@/helpers/converters'
import vSelect from 'vue-select'
import { VMoney } from 'v-money'
import { getBRLFormat } from '@/helpers/formatting'
import { getVueSelectErrorClass } from '@/helpers/validators'
import * as types from '../store/types'

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BCol,
    BRow,
    BSpinner,
    vSelect,
  },
  directives: {
    Ripple,
    money: VMoney,
  },
  data() {
    return {
      saving: false,
      loading: {
        form: false,
      },
      isValid: true,
      maxPointsFocused: 0,
      maxPointsProtagonist: 0,
      currentWeek: this.getCurrentWeek(),
      numberOfWeeksInMonth: this.getNumberOfWeeksInMonth(),
      apprentice: undefined,
      focusedWeek1: undefined,
      focusedWeek2: undefined,
      focusedWeek3: undefined,
      focusedWeek4: undefined,
      focusedWeek5: undefined,
      protagonistWeek1: undefined,
      protagonistWeek2: undefined,
      protagonistWeek3: undefined,
      protagonistWeek4: undefined,
      protagonistWeek5: undefined,
      champion: undefined,
      certifiedPnhunter: undefined,
      certifiedPnclouser: undefined,
      certifiedP1: undefined,
      certifiedP2: undefined,
      certifiedP3: undefined,
      certifiedP4: undefined,
      certifiedP5: undefined,
      meetingPresence: undefined,
      monthlyMeetingPresence: undefined,
      pec: undefined,
      externalCertificate: undefined,
      teamAverage4WsPartners: undefined,
      continuousLearning: undefined,
      averageTeam4Ws: undefined,
      debits: undefined,
      pnsRespondedCsat: undefined,
      pnsApprovedWsCeo: undefined,
      recClEf: undefined,
      recPnEf: undefined,
      focusedWeek1Disabled: true,
      focusedWeek2Disabled: true,
      focusedWeek3Disabled: true,
      focusedWeek4Disabled: true,
      focusedWeek5Disabled: true,
      protagonistWeek1Disabled: true,
      protagonistWeek2Disabled: true,
      protagonistWeek3Disabled: true,
      protagonistWeek4Disabled: true,
      protagonistWeek5Disabled: true,
      months: [],
      selectedDate: undefined,
      year: undefined,
      month: undefined,
      isPreviousMonth: false,
    }
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() }
  },
  computed: {
    ...mapGetters({
      newDnaCampaignSidebar: types.NEW_DNA_CAMPAIGN_SIDEBAR,
    }),
    currentMonth() {
      const date = new Date();
      return date.getMonth() + 1;
    },
    currentYear() {
      return new Date().getFullYear();
    },
  },
  watch: {
    numberOfWeeksInMonth() {
      this.updateFieldsBasedOnWeeks()
    },

    selectedDate(newVal) {
      if (newVal) {
        const [year, month] = newVal.split('/');
        this.year = year;
        this.month = month;

        this.onShow();
        this.checkPreviousMonth();
      }
    },

    currentWeek() {
      this.updateFieldsBasedOnWeeks()
    },
  },
  mounted() {
    this.updateFieldsBasedOnWeeks()
    this.generateMonths()
    this.onShow()
  },
  methods: {
    ...mapMutations({
      mutateNewDnaCampaignSidebar: types.MUTATE_NEW_DNA_CAMPAIGN_SIDEBAR,
    }),
    ...mapActions({
      getDnaCampaign: types.GET_DNA_CAMPAIGN,
      saveDnaCampaign: types.SAVE_DNA_CAMPAIGN,
    }),
    getSelectErrorClass(thereIsError) {
      return getVueSelectErrorClass(thereIsError)
    },
    getCurrentWeek() {
      const today = new Date()
      const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
      const days = Math.floor((today - startOfMonth) / (24 * 60 * 60 * 1000))
      return Math.ceil((days + 1) / 7)
    },

    getNumberOfWeeksInMonth() {
      const today = new Date()
      const month = today.getMonth()
      const firstDayOfMonth = new Date(today.getFullYear(), month, 1)
      const lastDayOfMonth = new Date(today.getFullYear(), month + 1, 0)
      const totalDays = (lastDayOfMonth - firstDayOfMonth) / (1000 * 60 * 60 * 24)
      return Math.ceil(totalDays / 7)
    },

    updateFieldsBasedOnWeeks() {
      if (this.numberOfWeeksInMonth === 4) {
        this.maxPointsFocused = 100 / 4
        this.maxPointsProtagonist = 240 / 4
        this.focusedWeek5 = null
        this.protagonistWeek5 = null
        this.focusedWeek5Disabled = true
        this.protagonistWeek5Disabled = true
      } else {
        this.maxPointsFocused = 100 / 5
        this.maxPointsProtagonist = 240 / 5
        this.focusedWeek5Disabled = false
        this.protagonistWeek5Disabled = false
      }

      this.disablePreviousWeeks()
    },

    disablePreviousWeeks() {
      this.focusedWeek1Disabled = this.currentWeek > 1
      this.focusedWeek2Disabled = this.currentWeek > 2
      this.focusedWeek3Disabled = this.currentWeek > 3
      this.focusedWeek4Disabled = this.currentWeek > 4
      this.protagonistWeek1Disabled = this.currentWeek > 1
      this.protagonistWeek2Disabled = this.currentWeek > 2
      this.protagonistWeek3Disabled = this.currentWeek > 3
      this.protagonistWeek4Disabled = this.currentWeek > 4
    },

    validateInputs(field, maxValue) {
      if (this[field] !== undefined && this[field] !== null) {
        const value = this[field]

        if (value < 0 || value > maxValue) {
          this.isValid = false
        } else {
          this.isValid = true
        }

        if (value < 0) {
          this[field] = 0
        } else if (value > maxValue) {
          this[field] = maxValue
        }
      }
    },

    validateInputsWeekFocused(field) {
      const totalFocused = this.focusedWeek1 + this.focusedWeek2 + this.focusedWeek3 + this.focusedWeek4 + this.focusedWeek5

      if (totalFocused > 100) {
        alert('O total de pontos de Focado excede 100.')
      }

      const value = this[field]

      if (value < 0) {
        this[field] = 0
      } else if (value > this.maxPointsFocused) {
        this[field] = this.maxPointsFocused
      }
    },

    validateInputsWeekProtagonist(field) {
      const totalProtagonist = this.protagonistWeek1 + this.protagonistWeek2 + this.protagonistWeek3 + this.protagonistWeek4 + this.protagonistWeek5

      if (totalProtagonist > 240) {
        alert('O total de pontos de Protagonista excede 240.')
      }

      const value = this[field]

      if (value < 0) {
        this[field] = 0
      } else if (value > this.maxPointsProtagonist) {
        this[field] = this.maxPointsProtagonist
      }
    },

    generateMonths() {
      const today = new Date();
      const months = [];
      for (let i = 12; i >= 0; i--) {
        const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        months.push(`${year}/${month}`);
      }
      this.months = months.reverse()
      this.selectedDate = this.months[0];
    },

    checkPreviousMonth() {
      const selectedMonth = parseInt(this.month, 10);
      const selectedYear = parseInt(this.year, 10);

      const lastMonth = this.currentMonth - 1 === 0 ? 12 : this.currentMonth - 1;
      const lastMonthYear = this.currentMonth - 1 === 0 ? this.currentYear - 1 : this.currentYear;

      if (selectedMonth === lastMonth && selectedYear === lastMonthYear) {
        this.isPreviousMonth = true;
      } else {
        this.isPreviousMonth = false;
      }
    },

    onShow() {
      this.loading.form = true
      this.getDnaCampaign({
        year_reference: this.year,
        month_reference: this.month,
        consultant_id: this.newDnaCampaignSidebar.consultant_id,
      })
        .then(response => {
          if (response && response.data) {
            this.apprentice = response.data.data.apprentice
            this.focusedWeek1 = response.data.data.focused_week_1
            this.focusedWeek2 = response.data.data.focused_week_2
            this.focusedWeek3 = response.data.data.focused_week_3
            this.focusedWeek4 = response.data.data.focused_week_4
            this.focusedWeek5 = response.data.data.focused_week_5
            this.protagonistWeek1 = response.data.data.protagonist_week_1
            this.protagonistWeek2 = response.data.data.protagonist_week_2
            this.protagonistWeek3 = response.data.data.protagonist_week_3
            this.protagonistWeek4 = response.data.data.protagonist_week_4
            this.protagonistWeek5 = response.data.data.protagonist_week_5
            this.champion = response.data.data.champion
            this.certifiedPnhunter = response.data.data.certified_pnhunter
            this.certifiedPnclouser = response.data.data.certifiedPnclouser
            this.certifiedP1 = response.data.data.certified_p1
            this.certifiedP2 = response.data.data.certified_p2
            this.certifiedP3 = response.data.data.certified_p3
            this.certifiedP4 = response.data.data.certified_p4
            this.certifiedP5 = response.data.data.certified_p5
            this.meetingPresence = response.data.data.meeting_presence
            this.monthlyMeetingPresence = response.data.data.monthly_meeting_presence
            this.pec = response.data.data.pec
            this.externalCertificate = response.data.data.external_certificate
            this.teamAverage4WsPartners = response.data.data.team_average_4_ws_partners
            this.continuousLearning = response.data.data.continuous_learning
            this.averageTeam4Ws = response.data.data.average_team_4_ws
            this.debits = response.data.data.debits
            this.pnsRespondedCsat = response.data.data.pns_responded_csat
            this.pnsApprovedWsCeo = response.data.data.pns_approved_ws_ceo
            this.recClEf = response.data.data.rec_cl_ef
            this.recPnEf = response.data.data.rec_pn_ef
          }
        })
        .catch(() => {
          this.clear()
        })
        .finally(() => {
          this.loading.form = false
        })
    },
    async save() {
      const isFormCorrect = await this.v$.$validate()

      if (!isFormCorrect) return

      this.saving = true
      const {
        apprentice,
        focusedWeek1,
        focusedWeek2,
        focusedWeek3,
        focusedWeek4,
        focusedWeek5,
        protagonistWeek1,
        protagonistWeek2,
        protagonistWeek3,
        protagonistWeek4,
        protagonistWeek5,
        champion,
        certifiedPnhunter,
        certifiedPnclouser,
        certifiedP1,
        certifiedP2,
        certifiedP3,
        certifiedP4,
        certifiedP5,
        meetingPresence,
        monthlyMeetingPresence,
        pec,
        externalCertificate,
        teamAverage4WsPartners,
        continuousLearning,
        averageTeam4Ws,
        debits,
        pnsRespondedCsat,
        pnsApprovedWsCeo,
        recClEf,
        recPnEf,
      } = this
      this.saveDnaCampaign({
        year_reference: this.year,
        month_reference: this.month,
        consultant_id: this.newDnaCampaignSidebar.consultant_id,
        apprentice,
        focused_week_1: focusedWeek1,
        focused_week_2: focusedWeek2,
        focused_week_3: focusedWeek3,
        focused_week_4: focusedWeek4,
        focused_week_5: focusedWeek5,
        protagonist_week_1: protagonistWeek1,
        protagonist_week_2: protagonistWeek2,
        protagonist_week_3: protagonistWeek3,
        protagonist_week_4: protagonistWeek4,
        protagonist_week_5: protagonistWeek5,
        champion,
        certified_pnhunter: certifiedPnhunter,
        certified_pnclouser: certifiedPnclouser,
        certified_p1: certifiedP1,
        certified_p2: certifiedP2,
        certified_p3: certifiedP3,
        certified_p4: certifiedP4,
        certified_p5: certifiedP5,
        meeting_presence: meetingPresence,
        monthly_meeting_presence: monthlyMeetingPresence,
        pec,
        external_certificate: externalCertificate,
        team_average_4_ws_partners: teamAverage4WsPartners,
        continuous_learning: continuousLearning,
        average_team_4_ws: averageTeam4Ws,
        debits,
        pns_responded_csat: pnsRespondedCsat,
        pns_approved_ws_ceo: pnsApprovedWsCeo,
        rec_cl_ef: recClEf,
        rec_pn_ef: recPnEf,
      })
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Sucesso!',
              text: 'Campanha salva com sucesso.',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.mutateNewDnaCampaignSidebar({ visible: false })
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Oops!',
              text: 'Ocorreu um erro ao salvar a campanha. Entre em contato com o setor de TI.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.saving = false
        })
    },
    clear() {
      this.apprentice = undefined
      this.focusedWeek1 = undefined
      this.focusedWeek2 = undefined
      this.focusedWeek3 = undefined
      this.focusedWeek4 = undefined
      this.focusedWeek5 = undefined
      this.protagonistWeek1 = undefined
      this.protagonistWeek2 = undefined
      this.protagonistWeek3 = undefined
      this.protagonistWeek4 = undefined
      this.protagonistWeek5 = undefined
      this.champion = undefined
      this.certifiedPnhunter = false
      this.certifiedPnclouser = false
      this.certifiedP1 = false
      this.certifiedP2 = false
      this.certifiedP3 = false
      this.certifiedP4 = false
      this.certifiedP5 = false
      this.meetingPresence = undefined
      this.monthlyMeetingPresence = undefined
      this.pec = undefined
      this.externalCertificate = undefined
      this.teamAverage4WsPartners = undefined
      this.continuousLearning = undefined
      this.averageTeam4Ws = undefined
      this.debits = undefined
      this.pnsRespondedCsat = undefined
      this.pnsApprovedWsCeo = undefined
      this.recClEf = undefined
      this.recPnEf = undefined
      this.mutateNewDnaCampaignSidebar({ id: undefined })
      this.v$.$reset()
    },
  },
}
</script>

<style lang="scss">
.sidebar-bottom {
  position: absolute;
  bottom: 0;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}
</style>
