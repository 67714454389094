import { 
  INACTIVE_PN_CONTRACTS_REPORT,
  INACTIVE_PN_CUSTOMERS_REPORT,
  CONTRACTS_AND_CUSTOMERS_REPORT,
  EPS_SUBSCRIPTIONS_AND_SOLUTIONS_REPORT,
  MANAGE_ACTION, 
  OVERDUE_SIGNATURE_REPORT,
  READ_ACTION
} from "@/constants/resources";

export default [
  {
    title: "Relatórios",
    icon: "none",
    children: [
      {
        title: "Assinaturas inadimplentes",
        route: "overdue-signature-report",
        icon: "TrendingDownIcon",
        resource: OVERDUE_SIGNATURE_REPORT,
        action: READ_ACTION,
      },
      {
        title: "Contratos de PNs inativos",
        route: "inactive-pn-contracts-report",
        icon: "AlertTriangleIcon",
        resource: INACTIVE_PN_CONTRACTS_REPORT,
        action: READ_ACTION,
      },
      {
        title: "Clientes de PNs inativos",
        route: "inactive-pn-customers-report",
        icon: "UserXIcon",
        resource: INACTIVE_PN_CUSTOMERS_REPORT,
        action: READ_ACTION,
      },
      {
        title: "Clientes e contratos",
        route: "contracts-and-customers",
        icon: "FileIcon",
        resource: CONTRACTS_AND_CUSTOMERS_REPORT,
        action: READ_ACTION,
      },
      {
        title: "EPs, assinaturas e soluções",
        route: "eps-subscriptions-and-solutions",
        icon: "FileIcon",
        resource: EPS_SUBSCRIPTIONS_AND_SOLUTIONS_REPORT,
        action: READ_ACTION,
      },
    ],
  },
];
