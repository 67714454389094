<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div 
        class="sidebar-content todo-sidebar"
        :class="{'show': mqShallShowLeftSidebar}"
        @click="$emit('close-left-sidebar')"
      >
        <div class="todo-app-menu">
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >
            <!-- Filters -->
            <b-list-group class="list-group-filters mt-2">
              <b-list-group-item
                v-for="filter in menuItems"
                :key="filter.title + $route.path"
                :to="filter.route"
                :active="isDynamicRouteActive(filter.route)"
                @click="$emit('close-left-sidebar')"
              >
                <feather-icon
                  :icon="filter.icon"
                  size="18"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ filter.title }}</span>
              </b-list-group-item>
            </b-list-group>
          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BButton, BListGroup, BListGroupItem } from 'bootstrap-vue'
import { isDynamicRouteActive } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import * as types from '@/modules/contract/store/types'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
  },
  props: {
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    return {
      perfectScrollbarSettings,
      isDynamicRouteActive,
    }
  },
  data() {
    return {
      menuItems: [
        { title: 'Informações gerais', icon: 'FileTextIcon', route: { name: 'contract-details-summary' } },
        { title: 'Pagamentos', icon: 'DollarSignIcon', route: { name: 'contract-details-payments' } },
        { title: 'Comissões', icon: 'PercentIcon', route: { name: 'contract-details-commissions' } },
        { title: 'Arquivos', icon: 'PaperclipIcon', route: { name: 'contract-details-files' } },
        { title: 'Histórico', icon: 'ListIcon', route: { name: 'contract-details-history' } },
      ],
    };
  },
  computed: {
    ...mapGetters({
      details: types.DETAILS_GENERAL_INFORMATION,
    })
  },
  watch: {
    details: function (newVal) {
      if(newVal?.has_transactions) {
        this.menuItems = [
          { title: 'Informações gerais', icon: 'FileTextIcon', route: { name: 'contract-details-summary' } },
          { title: 'Transações', icon: 'CreditCardIcon', route: { name: 'contract-details-transactions' } },
          { title: 'Pagamentos', icon: 'DollarSignIcon', route: { name: 'contract-details-payments' } },
          { title: 'Comissões', icon: 'PercentIcon', route: { name: 'contract-details-commissions' } },
          { title: 'Arquivos', icon: 'PaperclipIcon', route: { name: 'contract-details-files' } },
          { title: 'Histórico', icon: 'ListIcon', route: { name: 'contract-details-history' } },
        ]
      }
    },
  }
}
</script>
