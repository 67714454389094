export const PNH_ROLE_ID = 10;
export const PNC_ROLE_ID = 9;
export const PNP_ROLE_ID = 8;
export const APRENDIZ_ROLE_ID = 14;
export const PNF_ROLE_ID = 7;
export const EXPANSAO_ROLE_ID = 19;
export const PNM1_ROLE_ID = 6;
export const PNM2_ROLE_ID = 5;
export const PNM3_ROLE_ID = 4;
export const REGIONAL_ROLE_ID = 20;
export const NACIONAL_ROLE_ID = 21;
export const PNS_ROLE_ID = 3;
export const FUTURO_ROLE_ID = 15;
export const FF_ROLE_ID = 2;
export const EMPRESA_ROLE_ID = 1;

export const PNP1_ROLE_ID = 11;
export const PNP2_ROLE_ID = 12;
export const PNP3_ROLE_ID = 13;
export const FOCADO_ROLE_ID = 16;
export const PROTAGONISTA_ROLE_ID = 17;
export const CAMPEAO_ROLE_ID = 18;

export const EXECUTIVE_ROLES = [PNP1_ROLE_ID, PNP2_ROLE_ID, PNP3_ROLE_ID];

export const PNP_OR_HIGHER_ROLES = [
  PNP_ROLE_ID,
  APRENDIZ_ROLE_ID,
  PNF_ROLE_ID,
  EXPANSAO_ROLE_ID,
  PNM1_ROLE_ID,
  PNM2_ROLE_ID,
  REGIONAL_ROLE_ID,
  PNM3_ROLE_ID,
  NACIONAL_ROLE_ID,
  PNS_ROLE_ID,
  FUTURO_ROLE_ID,
  FF_ROLE_ID,
  EMPRESA_ROLE_ID,
  PNP1_ROLE_ID,
  PNP2_ROLE_ID,
  PNP3_ROLE_ID,
  FOCADO_ROLE_ID,
  PROTAGONISTA_ROLE_ID,
  CAMPEAO_ROLE_ID
];

export function isPnpOrHigher(consultant_role) {
  return consultant_role && PNP_OR_HIGHER_ROLES.includes(consultant_role);
}

export const CUSTOMER_USER_ROLE_ID = 5;
export const PNH_USER_ROLE_ID = 6;
export const PNP_USER_ROLE_ID = 7;
export const APRENDIZ_USER_ROLE_ID = 37;
export const EXTERNAL_USER_ROLE_ID = 22;
export const PNC_USER_ROLE_ID = 24;
export const PNP1_USER_ROLE_ID = 25;
export const PNP2_USER_ROLE_ID = 26;
export const PNP3_USER_ROLE_ID = 27;
export const FOCADO_USER_ROLE_ID = 38;
export const PROTAGONISTA_USER_ROLE_ID = 39;
export const CAMPEAO_USER_ROLE_ID = 40;

export const PNF_USER_ROLE_ID = 8;
export const EXPANSAO_USER_ROLE_ID = 41;
export const PNM1_USER_ROLE_ID = 9;
export const PNM2_USER_ROLE_ID = 10;
export const PNM3_USER_ROLE_ID = 11;
export const REGIONAL_USER_ROLE_ID = 42;
export const NACIONAL_USER_ROLE_ID = 43;
export const PNS_USER_ROLE_ID = 12;
export const FUTURO_USER_ROLE_ID = 44;
export const FF_USER_ROLE_ID = 13;

export const FRANCHISE_OR_HIGHER_USER_ROLES = [
  PNF_USER_ROLE_ID,
  EXPANSAO_USER_ROLE_ID,
  PNM1_USER_ROLE_ID,
  PNM2_USER_ROLE_ID,
  REGIONAL_USER_ROLE_ID,
  PNM3_USER_ROLE_ID,
  NACIONAL_USER_ROLE_ID,
  PNS_USER_ROLE_ID,
  FUTURO_USER_ROLE_ID,
  FF_USER_ROLE_ID
];

export const COMMON_USER_ROLES = [
  CUSTOMER_USER_ROLE_ID,
  PNH_USER_ROLE_ID,
  PNP_USER_ROLE_ID,
  APRENDIZ_USER_ROLE_ID,
  EXTERNAL_USER_ROLE_ID,
  PNC_USER_ROLE_ID,
  PNP1_USER_ROLE_ID,
  PNP2_USER_ROLE_ID,
  PNP3_USER_ROLE_ID,
  FOCADO_USER_ROLE_ID,
  PROTAGONISTA_USER_ROLE_ID,
  CAMPEAO_USER_ROLE_ID
];

export function isNotCommonUser(user_role) {
  return user_role && !COMMON_USER_ROLES.includes(user_role);
}

export function isFranchiseOrHigherByUserRole(user_role) {
  return user_role && FRANCHISE_OR_HIGHER_USER_ROLES.includes(user_role);
}
