import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  currentSearchResult: undefined,
  historicalSearchResult: undefined,
  pastFutureSearchResult: undefined,
  commissionProducts: [],
  currentPage: undefined,
  searchResult: undefined,
  paymentConciliators: [],
  financialSolutions: undefined,
  paymentProducts: [],
  commissionManagementTotalizer: {
    solution_received: 0,
    solution_qtd: 0,
    ep_received: 0,
    ep_qtd: 0,
    sub_received: 0,
    sub_qtd: 0
  },
  chartResult: undefined,
  pastCommissionsTotal: undefined,
  pastCommissionsChart: undefined
};

export default {
  state,
  actions,
  getters,
  mutations,
};
